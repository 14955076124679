import axiosInstance from "../../modules/network";

export const ContactFetch = () => {
    const requestInformation = async (revNo) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation/previous?revNo=${revNo}`)
            return result
        } catch (error) {
            return error.response
        }
    }

    return {requestInformation}
}
