import React, {useRef, useState} from "react";
import KakaoMap from "./KakaoMap";
import Buttons from "./Buttons";
import SnackBar from "./SnackBar";
import {useNavigate} from "react-router-dom";


function KakaoMapContainer({company, address1, address2, addressPost, tel}) {

    const [showSnackBar, setShowSnackBar] = useState(false)
    const navigate = useNavigate();
    let addressRef = useRef()
    const handleClickCopy = () => {

        addressRef.current.type = 'text';
        addressRef.current.select();
        document.execCommand("copy");
        addressRef.current.type = 'hidden';

        alert(addressRef.current.value + ' 주소가 복사되었습니다.');
    }

    const handleClickKakaoNavi = (address) => {

        navigate("/kakaonavi", {state: {address: address}});
    }
    return (
        <div className="kakao_map_container">

            {address1 ?
                <div className="mb16">
                    <KakaoMap address={address1}/>
                </div>
                : <div className="info_box_yellow mv24 mb12">
                    location 정보 없음 </div>}

            <div className={"map_contents"}>
                <input value={`${address1} ${address2}`} ref={addressRef} type="hidden"/>
                <span className={"headline_18pt"}>{company}</span>
                <p className="ch20 c222222">{address1} {address2} {addressPost}</p>
            </div>
            <div className={"map_btn_wrap"}>
                <Buttons value={"전화하기"} size={"extra_small"} type={"outlined"} icon={"perm_phone_msg"} onClick={() => {
                    window.location.href = `tel://${tel}`;
                }}/> <Buttons value={"주소복사"} size={"extra_small"} type={"outlined"} icon={"content_copy"} onClick={() => {
                handleClickCopy();
            }}/> <Buttons value={"카카오내비"} size={"extra_small"} type={"outlined"} icon={"near_me"} onClick={() => handleClickKakaoNavi(address1)}/>

            </div>
            {showSnackBar &&
                <SnackBar showSnackBar={showSnackBar} setShowSnackBar={setShowSnackBar} content="주소를 복사했습니다"/>}
        </div>
    )
}

export default KakaoMapContainer