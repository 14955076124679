/**
 * 방문응답
 * */

import React, {useEffect, useState} from 'react';
import style from './Agreement.module.scss';
import GreenList from "../../components/GreenList";
import KakaoMapContainer from "../../components/KakaoMapContainer";
import {useSearchParams} from "react-router-dom";
import {PaymentFetch} from "../../model/Payment/Payment";
import * as common from "../../modules/Common";
import Toast from "../../components/Toast";
import SnackBar from "../../components/SnackBar";
import {AgreementFetch} from "../../model/Visit/Agreement";

export default function Agreement() {

    let [searchParams] = useSearchParams()
    let revNo = searchParams.get("revNo")

    const {detail} = PaymentFetch()
    const {visitAgree} = AgreementFetch()
    const [information, setInformation] = useState({})

    const [showSnack, setShowSnack] = useState(false)

    useEffect(() => {
        detail(revNo)
            .then(result => setInformation(result.data))
    }, [])


    const onVisitAgree = () => {
        const params = {
            "revNo": revNo,
            "visitStatus": "V00002"
        }
        visitAgree(params).then(result => {
            console.log(result.status)
            if (result.status === 200) {
                setShowSnack(true)
            }
            else {
                alert(result.message)
            }
        })
    }

    return (<>
        <div id="wrap" className="bg_beige_light">
            <div className="container">
                <div id={"view"} className={"mb93"}>
                    <div className={"logo_wrap y73"}>
                        <embed type="image/svg+xml" src="/image/primary_logo.svg"/>
                    </div>
                    <div className={"mb24"}>
                        <Toast text={"예약이 확정되었습니다."} image={"lightbulb_outline.png"}/>
                    </div>

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>예약정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "예약 번호",
                                    content: `${information.reservationResponse?.revNo || "-"}`
                                },
                                {
                                    label: "예약 일시",
                                    content: `${information.reservationResponse?.revDateTime || "-"}`
                                },
                                {
                                    label: "예약 상태",
                                    status: `${information.reservationResponse?.revStatus || "-"}`
                                },
                                {
                                    label: "업체명",
                                    content: `${information.reservationResponse?.cpName || "-"}`
                                },
                                {
                                    label: "서비스 정보",
                                    content: `${information.reservationResponse?.serviceName || "-"}`
                                },
                                // {
                                //     label: "예상결제금액",
                                //     content: `${common.numberWithCommas(information.reservationResponse?.expectedPrice) || "0"}원`
                                // },
                                {
                                    label: "예약금",
                                    content: `${common.numberWithCommas(information.reservationResponse?.deposit) || "0"}원`
                                }
                            ]}/>
                        </div>
                    </div>

                    {information.storeReservationInfoResponse?.reservationInfoContents &&
                        <div className={"info_wrap"}>
                            <h3 className={"subheader_16pt"}>
                                <span className="material-icons mr8">
                                    notification_important
                                </span>
                                예약 시 안내 사항
                            </h3>

                            <ul className={"subheader_14pt"}>
                                <li dangerouslySetInnerHTML={{__html: information.storeReservationInfoResponse?.reservationInfoContents.replace(/\n/g, '<br/>') || ''}}/>
                            </ul>

                        </div>}

                    {information.reservationResponse?.deposit !== 0 &&
                        <div className={"warning_wrap"}>
                            <h3 className={"subheader_16pt"}>
                                <span className="material-icons mr8">notification_important</span>
                                예약금 정책
                            </h3>
                            <ul className={"subheader_14pt mb16"}>
                                {information.storeDepositRefundResponse?.fullRefund !== '-1' &&
                                    <li>{information.storeDepositRefundResponse?.fullRefund}일 전 취소: 100% 환불</li>
                                }

                                {information.storeDepositRefundResponse?.halfRefund !== '-1' &&
                                    <li>{information.storeDepositRefundResponse?.halfRefund}일 전 취소: 50% 환불</li>
                                } {information.storeDepositRefundResponse?.noRefund !== '-1' &&
                                <li>{information.storeDepositRefundResponse?.noRefund}일 전 취소: 0% 환불</li>
                            }
                                <li>당일예약 취소 또는 변경은 미방문(노쇼): 0% 환불</li>
                            </ul>
                        </div>}

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>반려동물 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려동물명",
                                    content: `${information.customerPetResponse?.ptName || '-'}`
                                }
                            ]}/>
                        </div>
                    </div>

                    {information.reservationResponse?.customerReqMemo &&
                        <div className={"pb24"}>
                            <div className={"y16"}>
                                <h2 className={"title headline_18pt"}>고객 요청사항</h2>
                            </div>
                            <div className={"customer_request headline_18pt"}>
                                {information.reservationResponse?.customerReqMemo || '-'}
                            </div>
                        </div>}

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>예약자 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려인명",
                                    content: `${information.customerResponse?.cbName || '-'}`
                                },
                                {
                                    label: "휴대폰 번호",
                                    content: `${information.customerResponse?.cbHp || '-'}`
                                }
                            ]}/>
                        </div>
                    </div>
                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>업체 정보</h2>
                        </div>
                        <div className={"map_wrap"}>
                            <KakaoMapContainer company={information.storeResponse?.cpName || ''} address1={information.storeResponse?.cpAddr || ''} address2={""} addressPost={""} tel={information.storeResponse?.cpTel || ''}/>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div className={style.bottom_wrap}>
            <span>방문응답</span>
            <button onClick={(e) => onVisitAgree()}>방문확정</button>
        </div>

        <SnackBar title={`방문을 확정하셨습니다.`} icon={"check"} color={"#93FD53"} showSnack={showSnack} setShowSnack={setShowSnack}/>
    </>)
}
