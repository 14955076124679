import React from "react";
import style from "../css/module/tab.module.scss"

export default function FillTabs(props) {

    const {tabs, size, selected, setSelected} = props

    const getFontSize = () => {
        if (size === "small") {
            return "caption_12pt_medium"
        }
        else if (size === "medium") {
            return "subheader_14pt"
        }
    }

    return (
        <div className={`${style.fillTab} ${style[size]} ${getFontSize()}`}>
            {tabs.map((tab, i) => {
                return (
                    <div key={i} className={`${style.element} ${selected === tab.value && style.activated}`} onClick={() => {
                        setSelected(tab.value)
                    }}>
                        {tab.image && <img src={tab.image} alt={tab.image}/>} {tab.icon && <span className={`material-icons-round ${style.icon}`}>{tab.icon}</span>} {tab.label}
                    </div>
                )
            })}
        </div>
    )
}
