import React from "react";
import style from "../css/module/controls.module.scss";
import CheckBox from "./CheckBox";
import Radio from "../components/Radio";
import Toggle from "./Toggle";

export default function Controls(props) {

    const {value, setValue} = props

    return (
        <div className={`${style.controls} ${style[props.size]} ${value && style.active} ${props.className}`} onClick={() => setValue(!value)}>
            <div className={"headline_18pt"}>
                {props.icon && <span className={`material-icons-round`}>{props.icon}</span>} {props.title}
            </div>

            {props.type === 'checkbox' &&
                <CheckBox type={'primary'} size={'normal'} value={value} setValue={setValue}/>}

            {props.type === 'radio' &&
                <Radio type={'primary'} size={'normal'} value={value} setValue={setValue} events={(value) => setValue(value)}/>}

            {props.type === 'toggle' &&
                <Toggle type={'primary'} size={'normal'} value={value} setValue={setValue}/>}
        </div>
    )
}
