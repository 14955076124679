import React, {useEffect, useState} from 'react';
import "../../css/View.scss";
import style from './Request.module.scss';
import Header from "../../components/Header";
import BottomButtons from "../../components/BottomButtons";
import InputBox from "../../components/InputBox";
import TextBox from "../../components/TextBox";
import DropdownTimePicker from "../../components/DropdownTimePicker";
import Modal from "../../components/Modal";
import DropdownDatePicker from "../../components/DropdownDatePicker";
import DropdownButton from "../../components/DropdownButton";
import BottomModalRadio from "../../components/BottomModalRadio";
import BottomModalProfileRadio from "../../components/BottomModalProfileRadio";
import {useLocation, useNavigate} from "react-router-dom";
import BottomModalPageRadio from "../../components/BottomModalPageRadio";
import {RequestFetch} from "../../model/Request/Request";

const convertTime = timeStr => {
    const [modifier, time] = timeStr.split(' ')
    let [hours, minutes] = time.split(':')
    if (modifier === 'PM') {
        hours = Number(hours) + 12
    }
    return `${hours}:${minutes}:00`
}

const needTimes = [
    {
        title: '아무 시간이나 괜찮아요',
        code: 'H00001'
    },
    {
        title: '오전시간에 가능해요',
        code: 'H00002'
    },
    {
        title: '오후시간에 가능해요',
        code: 'H00003'
    },
    {
        title: '시간을 선택하고 싶어요',
        code: 'H00004'
    },
]

const checkValid = (params) => {
    if (params.cbId === "") {
        alert('고객정보가 누락되었습니다.')
        return false
    }
    if (params.cpId === "") {
        alert('업체정보가 누락되었습니다.')
        return false
    }
    if (params.svNo === "") {
        alert('서비스 정보가 누락되었습니다.')
        return false
    }
    if (params.revInquiryDt === "") {
        alert('예약 날짜가 누락되었습니다.')
        return false
    }
    return true
}

export default function Request() {

    const navigate = useNavigate()
    const location = useLocation()
    const information = location.state.information
    console.log(information)

    const [time, setTime] = useState("")
    const [date, setDate] = useState("")

    const [openModal1, setOpenModal1] = useState(false)

    const [popupNeedTime, setPopupNeedTime] = useState(false)
    const [needTime, setNeedTime] = useState("H00001")
    const [openTime, setOpenTime] = useState(false)
    const [needTitle, setNeedTitle] = useState(needTimes[0].title)
    const [popupNeedService, setPopupNeedService] = useState(false)
    const [needService, setNeedService] = useState('')
    const [serviceTitle, setServiceTitle] = useState("서비스명 선택")
    const [services, setServices] = useState('')
    const [popupNeedMananger, setPopupNeedManager] = useState(false)
    const [manager, setManager] = useState('')
    const [managers, setManagers] = useState('')
    const [managerTitle, setManagerTitle] = useState("아무나 괜찮아요")
    const [customerReqMemo, setCustomerReqMemo] = useState("")
    const [cTime, setCtime] = useState("")

    useEffect(() => {
        if (needTime === 'H00004') {
            setOpenTime(true)
        }
        else {
            setOpenTime(false)
        }
    }, [needTime])

    useEffect(() => {
        if (time !== '') {
            setCtime(convertTime(time))
        }
    }, [time])

    let params = {
        cbId: information.customerResponse.cbId,
        cpId: information.storeResponse.cpId,
        customerReqMemo: customerReqMemo,
        desiredRevCode: needTime,
        desiredRevStartAt: needTime !== 'H00004' ? '' : cTime,
        mbId: manager,
        ptCode: information.customerPetResponse.ptCode,
        revInquiryDt: date,
        svNo: needService,
        beforeRevNo: information.revNo
    }

    const cpId = information.storeResponse.cpId
    const {_, workList, managerList, serviceList, requestReservation} = RequestFetch()

    const [businessHours, setBusinessHours] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [serviceCurrentPage, setServiceCurrentPage] = useState(1)

    useEffect(() => {
        workList(cpId).then(result => {
            setBusinessHours(result.data)
        })
        managerList(cpId, currentPage).then(result => {
            setManagers(result.data)
        })
        serviceList(cpId, serviceCurrentPage).then(result => {
            setServices(result.data)
        })
    }, [])

    const sendData = () => {
        requestReservation(params).then(result => {
            if (result.success) {
                setOpenModal1(false)
                //const revNo = encodeURIComponent(aes256Encode(result.data))
                const revNo = result.data
                navigate(`/contact/progress?revNo=${revNo}`)
            }
        })
    }

    return (<>
        <div id="wrap" className="bg_beige_light">
            <Header title={"예약 문의하기"} backButton={false} type={"page"} closeButton={{
                onClick: () => navigate(-1)
            }}/>
            <div className="container pt64 pb92">
                <div className={`${style.input_wrap}`}>
                    <InputBox title={'업체명'} important={true} value={information.storeResponse.cpName} events={{
                        onChange: data => {
                        },
                    }} disabled={true}/>
                </div>
                <div className={`${style.input_wrap}`}>
                    <InputBox title={'반려견 및 고객명'} important={true} value={`${information.customerPetResponse.ptName} (${information.customerResponse.cbName})`} events={{
                        onChange: data => {
                        },
                    }} disabled={true}/>
                </div>
                <div className={`${style.input_wrap}`}>
                    <label className={`${style.title}  subheader_14pt`}>
                        예약 날짜
                        <span style={{color: '#FF5449', marginLeft: '4px'}}>*</span>
                    </label>
                    <DropdownDatePicker rounded={true} date={date} extra={businessHours} events={{
                        onChange: data => setDate(data),
                    }}/>
                </div>
                <div className={`${style.input_wrap}`}>
                    <label className={`${style.title}  subheader_14pt`}>
                        희망 시작 시간
                        <span style={{color: '#FF5449', marginLeft: '4px'}}>*</span>
                    </label>
                    <DropdownButton rounded={true} placeholder={needTitle} state={""} setState={""} size={"large"} events={e => setPopupNeedTime(true)}/>

                </div>
                {openTime &&
                    <div className={`${style.input_wrap}`}>
                        <DropdownTimePicker rounded={true} time={time} extra={businessHours} date={date} events={{
                            onChange: data => setTime(data),
                        }}/>
                    </div>
                }
                <div className={`${style.input_wrap}`}>
                    <label className={`${style.title}  subheader_14pt`}>
                        서비스명 선택
                        <span style={{color: '#FF5449', marginLeft: '4px'}}>*</span>
                    </label>
                    <DropdownButton rounded={true} placeholder={serviceTitle} state={needService} setState={setNeedService} size={"large"} events={e => setPopupNeedService(true)}/>
                </div>
                <div className={`${style.input_wrap}`}>
                    <label className={`${style.title}  subheader_14pt`}>
                        희망 담당자
                    </label>
                    <DropdownButton rounded={true} placeholder={managerTitle} state={manager} setState={setManager} size={"large"} events={e => {
                        setPopupNeedManager(true)
                    }}/>
                </div>
                <div className={`${style.input_wrap}`}>
                    <TextBox title={'요청사항'} size={'large'} value={customerReqMemo} placeholder="요청사항을 메모하세요." events={{
                        onChange: data => setCustomerReqMemo(data),
                    }}/>
                </div>
                <BottomButtons buttons={[
                    {
                        title: "문의하기",
                        onClick: () => {
                            //
                            if (checkValid(params)) {
                                setOpenModal1(true)
                            }
                        }
                    }
                ]}/>
            </div>

        </div>


        {openModal1 &&
            <Modal close={false} header={"예약 문의를 <br/>" +
                "요청하시겠습니까?"} headerStyle={"default"} setOpen={setOpenModal1} contentAlign={"left"} content={`<div>
                   예약문의시 부득이한 사정으로 문의가 취소될 수 있으며 업체에서 연락할 수 있습니다
               </div>`} buttonStyle={"upDown"} buttons={[
                {
                    title: "이해했어요",
                    onClick: () => {
                        sendData()
                    }
                },
                {
                    title: "취소",
                    onClick: () => {
                        setOpenModal1(false)
                    }
                }
            ]}> </Modal>}

        {popupNeedTime &&
            <BottomModalRadio close={true} header={"희망 시작 시간"} headerStyle={"default"} setOpen={setPopupNeedTime} contentAlign={"left"} value={needTime} setValue={setNeedTime} content={needTimes} buttonStyle={"upDown"} title={needTitle} setTitle={setNeedTitle} buttons={[
                {
                    title: "확인",
                    onClick: () => {
                        setPopupNeedTime(false)
                    }
                }
            ]}> </BottomModalRadio>}

        {popupNeedService &&
            <BottomModalPageRadio close={true} header={"서비스명 선택"} headerStyle={"default"} setOpen={setPopupNeedService} contentAlign={"left"} value={needService} setValue={setNeedService} content={services} buttonStyle={"upDown"} title={serviceTitle} setTitle={setServiceTitle} setCurrentPage={setServiceCurrentPage} buttons={[
                {
                    title: "확인",
                    onClick: () => {
                        setPopupNeedService(false)
                    }
                }
            ]}> </BottomModalPageRadio>}

        {popupNeedMananger &&
            <BottomModalProfileRadio close={true} header={"희망 담당자"} headerStyle={"default"} setOpen={setPopupNeedManager} contentAlign={"left"} value={manager} setValue={setManager} content={managers} buttonStyle={"upDown"} title={managerTitle} setTitle={setManagerTitle} setCurrentPage={setCurrentPage} buttons={[
                {
                    title: "확인",
                    onClick: () => {
                        setPopupNeedManager(false)
                    }
                }
            ]}> </BottomModalProfileRadio>
        }
    </>)
}
