import {lazy} from 'react';
import {Step1, Step2, Step3} from "../views/Components";
import Contact from "../views/contact/Contact";
import KakaoNavi from "../components/KakaoNavi";
import Request from "../views/request/Request";
import Progress from "../views/contact/Progress";
import Reservation from "../views/payment/Reservation";
import PaymentStep1 from "../views/payment/PaymentStep1";
import PaymentCancel from "../views/payment/PaymentCancel";
import PaymentCancelReason from "../views/payment/PaymentCancelReason";
import Code from "../views/Code";
import Agreement from "../views/visit/Agreement";
import PaymentComplete from "../views/payment/PaymentComplete";
import RequestTemp from "../views/request/RequestTemp";
import Landing from "../views/Landing";

const Components = lazy(() => import("../views/Components"));

const routes = [
    {
        path: '/', element: <Landing/>
    },
    {
        path: '/code', element: <Code/>
    },
    {
        path: '/temp', element: <RequestTemp/>
    },
    {
        path: 'contact',
        children: [
            {path: '', element: <Contact/>},
            {path: 'progress', element: <Progress/>},
        ]
    },
    {
        path: 'reservation',
        children: [
            {path: '', element: <Reservation/>},
            {path: 'payment', element: <PaymentStep1/>}
        ]
    },
    {
        path: 'payment',
        children: [
            {path: '', element: <Reservation/>},
            {path: 'step1', element: <PaymentStep1/>},
            {path: 'complete', element: <PaymentComplete/>},
            {
                path: 'cancel',
                children: [
                    {path: '', element: <PaymentCancel/>},
                    {path: 'reason', element: <PaymentCancelReason/>}
                ]
            }
        ]
    },
    {
        path: '/request', element: <Request/>
    },
    {
        path: '/agree', element: <Agreement/>
    },
    {
        path: 'components', element: <Components/>,
        children: [
            {path: 'step1', element: <Step1/>},
            {path: 'step2', element: <Step2/>},
            {path: 'step3', element: <Step3/>},
        ]
    },
    {
        path: "/kakaonavi",
        element: <KakaoNavi/>
    },
]

export default routes;

