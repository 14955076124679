import React from "react";
import style from "../css/module/tooltip.module.scss"

export default function Tooltips(props) {
    const {arrow, size, value, onClick, icon_left, icon_right} = props

    const getFontSize = () => {
        if (size === "small" || size === "medium") {
            return "caption_12pt_regular"
        }
        else if (size === "large") {
            return "body_14pt"
        }
    }

    const defaultProps = {
        className: `${style.tooltip} ${style[size]} ${style[arrow]} ${getFontSize()}`,
        onClick: onClick
    }

    return (
        <div {...defaultProps}>
            {icon_left && <span className={`material-icons-round ${style.icon} ${style.icon_left}`}>{icon_left}</span>} {value} {icon_right && <span className={`material-icons-round ${style.icon} ${style.icon_right}`}>{icon_right}</span>}
        </div>
    )
}
