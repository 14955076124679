import React from "react";
import style from "../css/module/tags.module.scss"

export default function Tags(props) {
    const {dark, color, text} = props

    return (
        <span className={`caption_12pt_medium ${style.tag} ${style[color]} ${dark && style.dark}`}>
            {text}
        </span>
    )
}
