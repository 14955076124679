import React from "react";
import style from "../css/module/header.module.scss"
import {useNavigate} from "react-router-dom";
import Buttons from "./Buttons";

export default function Header(props) {
    const {type = "contents", title, backButton, backUrl, button, closeButton} = props
    const navigate = useNavigate()

    return (
        <div className={`${style.header} ${style[type]}`}>
            <div className={`${style.header_content}`}>
                {backButton &&
                    <span className={`${style.header_left} material-icons-round`} onClick={() => backUrl ? navigate(backUrl, {replace: true}) : navigate(-1)}>arrow_back_ios</span>}<p className={`${style.header_center} ${type === "page" ? "headline_24pt" : "headline_18pt"}`}>{title}</p>{button &&
                <Buttons value={button.value} size={"extra_small"} type={button.type} icon={button.icon} onClick={button.onClick}/>} {closeButton && <button className={`material-icons-round ${style.closeButton}`} onClick={closeButton.onClick}>close</button>}
            </div>
        </div>
    )
}
