import {useEffect, useState} from "react"

const useScrollDetact = (cp, tp) => {

    const [currentPage, setCurrentPage] = useState(cp)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    })

    const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight
        const scrollTop = document.documentElement.scrollTop
        const clientHeight = document.documentElement.clientHeight
        if (scrollTop + clientHeight >= scrollHeight) {
            if (tp > currentPage) {
                setCurrentPage(currentPage + 1)
            }
        }
    }

    return [currentPage]
}

export default useScrollDetact