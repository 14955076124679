import React from "react";
import style from "../css/module/dropdown.module.scss";

export default function Dropdown(props) {
    const {
        eyebrow,
        eyebrowRequired,
        caption,
        optionList,
        state,
        setState,
        placeholder,
        rounded,
        size = "small",
        disabled
    } = props

    const handleChange = (e) => {
        e.preventDefault()
        //console.log(e.target.value)
        setState(parseInt(e.target.value) || e.target.value)
    }

    return (<>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]} ${eyebrow && style.eyebrow_container} ${disabled && style.disabled}`}>
                {eyebrow && <label className={`${style.eyebrow} ${eyebrowRequired ? style.required : ""} caption_12pt_medium`}>{eyebrow}</label>}
                <select className={`headline_18pt ${state === "" ? style.placeholder : ""}`} value={state} onChange={handleChange}>
                    {placeholder && <option value={""} disabled={true}>{placeholder}</option>}

                    {optionList.map((option, i) => {
                        return (<option value={option.id} key={i}>{option?.title}</option>)
                    })}
                </select>
                <span className={`material-icons-round ${style.arrow} ${state === "" && style.placeholder}`}>expand_more</span>
            </div>
            {caption && <p className={`${style.caption} caption_12pt_medium`}>{caption}</p>}
        </>
    )
}
