import axiosInstance from "../../modules/network";
import config from "../../modules/Config";

export const PaymentFetch = () => {
    const detail = async (revNo) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation?revNo=${revNo}`)
            return result.data
        }
        catch (err) {
            if (config.debug) {
                console.error(err)
            }
            else {
                console.trace(err)
            }
            return {}
        }
    }
    const paymentDetail = async (revNo) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation/pay?revNo=${revNo}`)
            return result.data
        }
        catch (err) {
            console.error(err)
        }
    }

    const paymentConfirm = async (params) => {
        try {
            const result = await axiosInstance.post(`/api/imp-request`, params)
            return result.data
        }
        catch (err) {
            console.error(err)
        }
    }
    const paymentVerification = async (params) => {
        try {
            const result = await axiosInstance.post(`/api/imp-verification`, params)
            return result.data
        }
        catch (err) {
            console.error(err)
        }
    }

    const paymentCancel = async (params) => {
        try {
            const result = await axiosInstance.post(`/api/imp-cancel`, params)
            return result
        }
        catch (error) {
            return error.response
        }
    }
    const reservationCancelDetail = async (revNo) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation/cancel?revNo=${revNo}`)
            return result.data
        }
        catch (err) {
            console.error(err)
        }
    }
    return {detail, paymentDetail, paymentConfirm, paymentVerification, paymentCancel, reservationCancelDetail}
}
