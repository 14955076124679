/**
 * 예약문의정보
 * 문의종료 된 경우 새로운 예약 문의 가능
 * */

import React, {useEffect, useState} from 'react';
import "../../css/View.scss";
import Toast from "../../components/Toast";
import GreenList from "../../components/GreenList";
import KakaoMapContainer from "../../components/KakaoMapContainer";
import SnackBar from "../../components/SnackBar";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RequestFetch} from "../../model/Request/Request";
import {ContactFetch} from "../../model/Contact/Contact";
import BottomButtons from "../../components/BottomButtons";

export default function Progress() {
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    let revNo = searchParams.get("revNo")

    const [information, setInformation] = useState({})

    const {reservation} = RequestFetch()
    const {requestInformation} = ContactFetch()

    useEffect(() => {
        reservation(revNo).then(result => setInformation(result.data))
    }, [])

    const [showSnack, setShowSnack] = useState(true)

    return (<>
        <div id="wrap" className="bg_beige_light">
            <div className="container">
                <div id={"view"} className={"mb93"}>
                    <div className={"logo_wrap y73"}>
                        <embed type="image/svg+xml" src="/image/primary_logo.svg"/>
                    </div>

                    <div className={"mb24"}>
                        {information.reservationInquiryResponse?.status !== 'I18002' &&
                            <Toast text={"업체에서 예약 확정을 위해 연락을 취할 수 있습니다."} image={"lightbulb_outline.png"}/>}

                        {information.reservationInquiryResponse?.status === 'I18002' &&
                            <Toast text={`종료사유: ${information.reservationInquiryResponse?.terminationReason}`} image={"lightbulb_outline.png"}/>}
                    </div>

                    {information.reservationInquiryResponse?.status !== 'I18002' &&
                        <div className={"pb24"}>
                            <div className={"y16"}>
                                <h2 className={"title headline_18pt"}>문의정보</h2>
                            </div>
                            <div>
                                <GreenList dataArr={[
                                    {
                                        label: "문의번호",
                                        content: `${information.reservationInquiryResponse?.inqUid || '-'}`
                                    },
                                    {
                                        label: "문의일시",
                                        content: `${information.reservationInquiryResponse?.inquiryDateTime || '-'}`
                                    },
                                    {
                                        label: "희망시간",
                                        content: `${information.reservationInquiryResponse?.desiredRevDateTime || '-'}`
                                    },
                                    {
                                        label: "문의상태",
                                        status: `${information.reservationInquiryResponse?.status || '-'}`
                                    },
                                    {
                                        label: "업체명",
                                        content: `${information.reservationInquiryResponse?.cpName || '-'}`
                                    },
                                    {
                                        label: "서비스 정보",
                                        content: `${information.reservationInquiryResponse?.serviceName || '-'}`
                                    }
                                ]}/>
                            </div>
                        </div>}

                    {information.reservationInquiryResponse?.status !== 'I18002' &&
                        <div className={"info_wrap"}>
                            <h3 className={"subheader_16pt"}>
                                <span className="material-icons mr8">notification_important</span>
                                예약 시 안내 사항
                            </h3>
                            <ul className={"subheader_14pt"}>
                                <li dangerouslySetInnerHTML={{__html: information.storeReservationInfoResponse?.reservationInfoContents.replace(/\n/g, '<br/>') || ''}}/>
                            </ul>
                        </div>}

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>반려동물 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려동물명",
                                    content: `${information.customerPetResponse?.ptName || '-'}`
                                }
                            ]}/>
                        </div>
                    </div>

                    {information.reservationInquiryResponse?.customerReqMemo &&
                        <div className={"pb24"}>
                            <div className={"y16"}>
                                <h2 className={"title headline_18pt"}>고객 요청사항</h2>
                            </div>
                            <div className={"customer_request headline_18pt"}>
                                {information.reservationInquiryResponse?.customerReqMemo || '-'}
                            </div>
                        </div>}

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>예약자 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려인명",
                                    content: `${information.customerResponse?.cbName || '-'}`
                                },
                                {
                                    label: "휴대폰 번호",
                                    content: `${information.customerResponse?.cbHp || '-'}`
                                }
                            ]}/>
                        </div>
                    </div>

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>업체 정보</h2>
                        </div>
                        <div className={"map_wrap"}>
                            <KakaoMapContainer company={information.storeResponse?.cpName || ''} address1={information.storeResponse?.cpAddr || ''} address2={""} addressPost={""} tel={information.storeResponse?.cpTel || ''}/>
                        </div>
                    </div>
                </div>
            </div>

            {information.reservationInquiryResponse?.status === 'I18002' &&
                <BottomButtons buttons={[
                    {
                        title: "새로운 예약 문의하기",
                        onClick: () => {
                            requestInformation(information.reservationInquiryResponse?.beforeRevNo)
                                .then(result => {
                                    if (result.status === 200) {
                                        navigate("/request", {state: {information: result.data.data}});
                                    }
                                    else {
                                        alert(result.message)
                                    }
                                })
                        }
                    }
                ]}/>}
        </div>

        {information.reservationInquiryResponse?.status !== 'I18002' &&
            <SnackBar title={"예약문의 요청 완료! 업체에서 확인중입니다."} icon={"check"} color={"#93FD53"} showSnack={showSnack} setShowSnack={setShowSnack}/>}
    </>)
}

