import React from "react";
import style from "../css/module/toggle.module.scss"

export default function Toggle(props) {

    const {value, setValue} = props

    const onClickHandler = (e) => {
        if (value === "Y") {
            setValue("N")
        }
        else if (value === "N") {
            setValue("Y")
        }
        else if (value === false) {
            setValue(true)
        }
        else if (value === true) {
            setValue(false)
        }
    }

    return (
        <div className={`${style.toggle} ${style[props.size]} ${style[props.type]} 
            ${(value === "N" || value === false) ? style.off : style.on}`} onClick={(e) => onClickHandler(e)}>
            <div className={style.circle}/>
        </div>
    )
}
