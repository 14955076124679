import React from "react";
import style from "../css/module/tab.module.scss"
import {NavLink} from "react-router-dom";

export default function Tabs(props) {

    const {tabs, size} = props

    const getFontSize = () => {
        if (size === "small") {
            return "subheader_14pt"
        }
        else if (size === "medium") {
            return "subheader_16pt"
        }
        else if (size === "large") {
            return "headline_22pt"
        }
    }

    return (
        <div className={`${style.tab} ${style[size]} ${getFontSize()}`}>
            {tabs.map((tab, i) => {
                return (
                    <NavLink to={tab.pathname} key={i} className={({isActive}) => `${style.link} ${isActive && style.activated}`}>{tab.label}</NavLink>
                )
            })}
        </div>
    )
}
