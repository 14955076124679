import React from "react";
import style from "../css/module/bottomSheet.module.scss"
import Buttons from "./Buttons";

export default function BottomSheet(props) {

    const {close, header, slider, buttons, setOpen, children} = props

    const renderButtons = (buttons) => {
        if (buttons.length === 1) {
            return (
                <Buttons value={buttons[0].title} size={"medium"} type={"default"} onClick={buttons[0].onClick}/>
            )
        }
        else if (buttons.length === 2) {
            return (<>
                <Buttons value={buttons[0].title} size={"medium"} type={"text"} onClick={buttons[0].onClick}/> <Buttons value={buttons[1].title} size={"medium"} type={"default"} onClick={buttons[1].onClick}/>
            </>)
        }
    }

    return (
        <div className={style.background}>
            <div className={style.bottom_sheet}>
                {slider && <span onClick={() => {
                    setOpen(false)
                }} className={style.slider}/>}

                <div className={style.header}>
                    {header && <p className={"headline_24pt"}>{header}</p>} {close && <span onClick={() => {
                    setOpen(false)
                }} className="material-icons-round">close</span>}
                </div>

                <div className={style.content}>
                    {children}
                </div>

                {buttons && <div className={style.buttons}>
                    {renderButtons(buttons)}
                </div>}
            </div>
        </div>
    )
}
