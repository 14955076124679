import React from "react";
import style from "../css/module/bottomButtons.module.scss"
import Buttons from "./Buttons";

export default function BottomButtons(props) {

    const {buttons} = props

    const renderButtons = (buttons) => {
        if (buttons.length === 1) {
            return (
                <Buttons value={buttons[0].title} size={"medium"} type={"default"} icon={buttons[0].icon} onClick={buttons[0].onClick} disabled={buttons[0].disable}/>
            )
        }
        else if (buttons.length === 2) {
            return (<>
                <Buttons value={buttons[0].title} size={"medium"} type={"outlined"} icon={buttons[0].icon} onClick={buttons[0].onClick}/> <Buttons value={buttons[1].title} size={"medium"} type={"default"} icon={buttons[1].icon} onClick={buttons[1].onClick}/>
            </>)
        }
    }

    return (
        <div className={style.bottom_buttons}>
            <div className={style.buttons}>
                {renderButtons(buttons)}
            </div>
        </div>
    )
}
