import React, {useState} from "react";
import style from "../css/module/inputbox.module.scss";

export default function InputBox(props) {
    const {title, important, required, value, type = "text", events, placeholder, size = "medium", password, confirm} = props

    const [passwordType, setPasswordType] = useState("password")

    const getFontSize = () => {
        if (size === "medium") {
            return "headline_18pt"
        }
        else if (size === "large") {
            return "headline_22pt"
        }
    }

    const handleChange = (e) => onChange(e.target.value);
    const {onChange} = events;
    const fieldProps = {
        value,
        placeholder,
        onChange: handleChange,
        disabled: props.disabled,
        type: password ? passwordType : type
    };

    return (
        <div>
            <label className={`${style.title} ${required && style.required} subheader_14pt`}>
                {title} {important && <span style={{color: '#FF5449', marginLeft: '4px'}}>*</span>}
            </label>
            <div className={`${style.inputbox} ${style[size]} ${props.error ? style.error : ''} ${props.disabled ? style.disabled : ''}`}>
                <input {...fieldProps} className={getFontSize()}/>

                {password && <span className={`material-icons-round ${style.icon}`} onClick={() => (passwordType === "password") ? setPasswordType("text") : setPasswordType("password")}>
                    {passwordType === "password" ? "visibility" : "visibility_off"}</span>}

                {confirm && <span className={`material-icons-round ${style.icon}`}>check</span>}
            </div>
            <p className={`${props.error ? style.error_message : ''} ${props.comment ? style.comment : ''} caption_12pt_regular`}>{props.comment}</p>
        </div>
    )
}
