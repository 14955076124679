import React, {useState} from 'react';
import Header from "../../components/Header";
import BottomButtons from "../../components/BottomButtons";
import {useLocation, useNavigate} from "react-router-dom";
import TextBox from "../../components/TextBox";
import * as payment from "../../model/Payment/Payment";
import SnackBar from "../../components/SnackBar";

export default function PaymentCancelReason() {
    const navigate = useNavigate()
    const location = useLocation()
    const revNo = location.state.revNo
    const [reason, setReason] = useState("")

    const [showSnack, setShowSnack] = useState(false)
    const [notification, setNotification] = useState('')
    const [icon, setIcon] = useState('check')

    const onCancel = () => {
        if (reason === '') {
            alert('취소 사유를 입력해주세요!')
            return
        }
        //const decodeRevNo = aes256Decode(decodeURIComponent(unescape(revNo)))
        const params = {
            "reason": reason,
            "revNo": revNo
        }
        payment.PaymentFetch().paymentCancel(params).then(result => {
            console.log(result)
            if (result.status === 200) {
                navigate(`/payment?revNo=${revNo}`)
            }
            else {
                setIcon('close')
                setNotification(result.data.message)
                setShowSnack(true)
            }
        })

    }

    return (<>
        <div id="wrap" className="bg_beige_light">
            <Header title={"예약 취소 사유입력"} backButton={true} type={"page"}/>
            <div className="container pt64 pb92">
                <div className={"mt16 mb24"}>
                    <TextBox size={'medium'} value={reason} placeholder="취소 사유를 입력해 주세요." events={{
                        onChange: data => setReason(data),
                    }}/>
                </div>
            </div>
        </div>
        <BottomButtons buttons={
            [
                {
                    title: '예약취소',
                    onClick: () => {
                        onCancel()
                    }
                }
            ]
        }/> <SnackBar title={notification} icon={icon} color={"#BA1B1B"} showSnack={showSnack} setShowSnack={setShowSnack}/>
    </>)
}
