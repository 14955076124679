/**
 * 예약증(알림톡에서 접근)
 * 새로운 예약 문의 가능
 * */

import React, {useEffect, useState} from 'react';
import "../../css/View.scss";
import Toast from "../../components/Toast";
import GreenList from "../../components/GreenList";
import KakaoMapContainer from "../../components/KakaoMapContainer";
import BottomButtons from "../../components/BottomButtons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ContactFetch} from "../../model/Contact/Contact";

export default function Contact() {

    const navigate = useNavigate()
    let [searchParams] = useSearchParams()

    let revNo = searchParams.get("revNo")

    const [information, setInformation] = useState({})

    const {requestInformation} = ContactFetch()

    useEffect(() => {
        requestInformation(revNo).then(result => {
            if (result.status === 200) {
                setInformation(result.data.data)
                console.log(result.data.data)
            }
            else {
                alert('올바른 접근이 아닙니다.')
                navigate(-1)
            }
        })
    }, [])

    return (<>
        <div id="wrap" className="bg_beige_light">
            <div className="container">
                <div id={"view"}>
                    <div className={"logo_wrap y73"}>
                        <embed type="image/svg+xml" src="/image/primary_logo.svg"/>
                    </div>

                    <div className={"mb24"}>
                        <Toast text={"업체의 정책에 따라 예약금이 부과될 수 있습니다."} image={"lightbulb_outline.png"}/>
                    </div>

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>반려동물 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려동물명",
                                    content: information?.customerPetResponse?.ptName || '-'
                                }
                            ]}/>
                        </div>
                    </div>

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>예약자 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려인명",
                                    content: information?.customerResponse?.cbName || '-'
                                },
                                {
                                    label: "휴대폰 번호",
                                    content: information?.customerResponse?.cbHp || '-'
                                }
                            ]}/>
                        </div>
                    </div>

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>업체 정보</h2>
                        </div>
                        <div className={"map_wrap"}>
                            <KakaoMapContainer company={information.storeResponse?.cpName || ''} address1={information.storeResponse?.cpAddr || ''} address2={""} addressPost={""} tel={information.storeResponse?.cpTel || ''}/>
                        </div>
                    </div>

                    <div className={"info_wrap mb93"}>
                        <h3 className={"subheader_16pt"}>
                            <span className="material-icons mr8">notification_important</span>
                            예약 시 안내 사항
                        </h3>
                        <ul className={"subheader_14pt mb16"}>
                            <li dangerouslySetInnerHTML={{__html: information?.storeReservationInfoResponse?.reservationInfoContents.replace(/\n/g, '<br/>') || ''}}/>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <BottomButtons buttons={[
            {
                title: "새로운 예약 문의하기",
                onClick: () => navigate("/request", {state: {information: information}})
            }
        ]}/>
    </>)
}
