import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import BottomButtons from "../../components/BottomButtons";
import style from './Payment.module.scss';
import {useLocation, useNavigate} from "react-router-dom";
import * as common from "../../modules/Common";
import Modal from "../../components/Modal";
import * as payment from "../../model/Payment/Payment"

export default function PaymentCancel() {
    const navigate = useNavigate()
    const location = useLocation()
    const revNo = location.state.revNo

    const [detail, setDetail] = useState({})
    const [cancelPayment, setCancelPayment] = useState(false)

    useEffect(() => {
        payment.PaymentFetch().reservationCancelDetail(revNo).then(result => {
            if (result.success) {
                setDetail(result.data)
            }
            else {
                alert(result.message)
            }
        })
    }, [])

    const refundPercent = () => {
        const refundPolicy = detail.depositRefundPolicyAndRefundPriceResponse?.refundPolicy
        let percent = ''
        if (refundPolicy === 'fullRefund') {
            percent = '100'
        }
        else if (refundPolicy === 'halfRefund') {
            percent = '50'
        }
        else if (refundPolicy === 'noRefund') {
            percent = '0'
        }
        return percent
    }

    const refund = () => {
        const totalRefundPrice = detail.depositRefundPolicyAndRefundPriceResponse?.totalRefundPrice
        let percent = refundPercent()
        return (
            <div className={`${style.list} headline_16pt`}>
                <span>총 환불금액 ({percent}%)</span>
                <span>{common.numberWithCommas(totalRefundPrice)} 원</span>
            </div>
        )
    }

    return (<>
        <div id="wrap" className="bg_beige_light">
            <Header title={"예약 취소하기"} backButton={true} type={"page"}/>
            <div className="container pt64 pb92">
                <div className={"mt16 mb24"}>
                    <div className={`${style.toast_message}`}>
                        <span className={"material-icons subheader_14pt"}>warning</span>
                        예약금 정책에 따라 {refundPercent()}%의 금액이 환불됩니다.
                    </div>
                </div>
                <div className={`${style.title} headline_18pt`}>
                    <span>예약금 환불</span>
                </div>
                <div className={`${style.list} ${style.bt_line} headline_16pt`}>
                    <span>예약금</span>
                    <span>{common.numberWithCommas(detail.deposit)} 원</span>
                </div>
                {
                    refund()
                }

                <div className={style.warning_wrap}>
                    <h3 className={"subheader_16pt"}>
                        <span className="material-icons mr8">
                            notification_important
                        </span>
                        예약금 정책
                    </h3>
                    <ul className={"subheader_14pt"}>
                        {detail.storeDepositRefundResponse?.fullRefund !== -1 &&
                            <li>{detail.storeDepositRefundResponse?.fullRefund}일 전 취소: 100% 환불</li>
                        }

                        {detail.storeDepositRefundResponse?.halfRefund !== -1 &&
                            <li>{detail.storeDepositRefundResponse?.halfRefund}일 전 취소: 50% 환불</li>
                        } {detail.storeDepositRefundResponse?.noRefund !== -1 &&
                        <li>{detail.storeDepositRefundResponse?.noRefund}일 전 취소: 0% 환불</li>
                    }
                        <li>미방문(노쇼): 0% 환불</li>
                    </ul>
                </div>
            </div>
        </div>
        <BottomButtons buttons={[
            {
                title: '예약취소',
                onClick: () => {
                    setCancelPayment(true)
                }
            }
        ]}/> {cancelPayment &&
        <Modal header={"예약을 취소하시겠어요?"} headerStyle={"default"} setOpen={setCancelPayment} contentAlign={"left"} buttonStyle={"upDown"} buttons={[
            {
                title: "네 취소할래요",
                onClick: () => {
                    navigate("/payment/cancel/reason", {state: {revNo: revNo}})
                }
            },
            {
                title: "아니오",
                onClick: () => {
                    setCancelPayment(false)
                }
            }
        ]}> </Modal>
    }
    </>)
}
