import CryptoJS from 'crypto-js';

const aes256SecretKey = process.env.REACT_APP_AES256_SECRET_KEY; //암호키
const aes256Iv = process.env.REACT_APP_AES256_IV; //임의키

export function aes256Encode(data) {
    const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(aes256SecretKey), {
        iv: CryptoJS.enc.Utf8.parse(aes256Iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    let aes256EncodeData = cipher.toString()
    //console.log("")
    //console.log("[aes256Encode] : [encode]")
    //console.log("[data] : " + aes256EncodeData)
    //console.log("")

    return aes256EncodeData
}

export function aes256Decode(data) {
    const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(aes256SecretKey), {
        iv: CryptoJS.enc.Utf8.parse(aes256Iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    let aes256DecodeData = cipher.toString(CryptoJS.enc.Utf8)
    /*console.log("")
    console.log("[aes256Decode] : [decode]")
    console.log("[data] : " + aes256DecodeData)
    console.log("")*/

    return aes256DecodeData
}
