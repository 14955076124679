import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {PaymentFetch} from "../../model/Payment/Payment";


export default function PaymentComplete() {
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    let revNo = searchParams.get("merchant_uid")
    let impUid = searchParams.get("imp_uid")

    //const encRevNo = encodeURIComponent(aes256Encode(revNo))
    const {paymentVerification} = PaymentFetch()

    useEffect(() => {
        const acceptParams = {
            "impUid": impUid,
            "revNo": revNo
        }
        console.log(acceptParams)
        paymentVerification(acceptParams).then(response => {
            console.log(`/payment?revNo=${revNo}`)
            if (response.success) {
                navigate(`/payment?revNo=${revNo}`)
            }
            else {
                alert("결제 실패")
                navigate(-1)
            }
        })
    }, [])

    return (<></>)
}
