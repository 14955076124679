import React from 'react';
import {useSearchParams} from "react-router-dom";
import {aes256Decode, aes256Encode} from "../modules/AesHandler";

export default function Code() {
    let [searchParams] = useSearchParams()
    const revNo = searchParams.get("revNo")
    const encRevNo = encodeURIComponent(aes256Encode(revNo))
    const decRevNo = aes256Decode(decodeURIComponent(unescape(revNo)))

    return (
        <>
            {escape(encRevNo)} <br/> {decRevNo}
        </>
    )
}
