import React, {useEffect} from 'react';
import style from "../css/module/snackBar.module.scss";

export default function SnackBar(props) {

    const {title, icon, button, showSnack, setShowSnack, color} = props

    useEffect(() => {
        if (showSnack) {
            setTimeout(() => {
                setShowSnack(false)
            }, 4000)
        }
    })

    return (<>
        {showSnack ?
            <div className={style.snack_bar}>
                {icon && <span className={`material-icons-round ${style.icon}`} style={{color: color}}>{icon}</span>}
                <span className={`body_14pt ${style.title}`}>{title}</span>

                {button?.type === "text" &&
                    <button className={`subheader_14pt ${style.textButton}`} onClick={button.onClick}>{button.value}</button>}

                {button?.type === "icon" && <button className={style.iconButton} onClick={button.onClick}>
                    <span className={"material-icons-round"}>{button.value}</span>
                </button>}
            </div>
            : <></>}
    </>)
}
