import React, {useState} from 'react';
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import Buttons from "../components/Buttons";
import BottomSheet from "../components/BottomSheet";
import Chips from "../components/Chips";
import Tooltips from "../components/Tooltips";
import Toggle from "../components/Toggle";
import CheckBox from "../components/CheckBox";
import Radio from "../components/Radio";
import Controls from "../components/Controls";
import InputBox from "../components/InputBox";
import TextBox from "../components/TextBox";
import Tabs from "../components/Tabs";
import FillTabs from "../components/FillTabs";
import Modal from "../components/Modal";
import SnackBar from "../components/SnackBar";
import Tags from "../components/Tags";
import List from "../components/List";
import Dropdown from "../components/Dropdown";
import Header from "../components/Header";
import GreenList from "../components/GreenList";
import BottomButtons from "../components/BottomButtons";

export default function Components() {

    const navigate = useNavigate();

    const [data1, setData1] = useState('')
    const [control, setControl] = useState(false)
    const [selected, setSelected] = useState("")

    const [selectedTab, setSelectedTab] = useState("")
    const [openBottomSheet1, setOpenBottomSheet1] = useState(false)
    const [openBottomSheet2, setOpenBottomSheet2] = useState(false)
    const [openModal1, setOpenModal1] = useState(false)
    const [openModal2, setOpenModal2] = useState(false)

    const [showSnack, setShowSnack] = useState(true)

    return (<>
        <div id="wrap" className="bg_beige_light">
            <Header title={"Components"} backButton={true} type={"page"} button={{
                value: "버튼",
                type: "default",
                onClick: () => console.log("hi")
            }}/>
            <div className="container pt64 pb92">
                <h1>Toggle</h1>
                <label>primary - normal</label>
                <Toggle type={'primary'} size={'normal'} value={control} setValue={setControl}/> <br/>
                <label>primary - large</label>
                <Toggle type={'primary'} size={'large'} value={control} setValue={setControl}/> <br/>
                <label>secondary - normal</label>
                <Toggle type={'secondary'} size={'normal'} value={control} setValue={setControl}/> <br/>
                <label>secondary - large</label>
                <Toggle type={'secondary'} size={'large'} value={control} setValue={setControl}/> <br/>

                <h1>CheckBox</h1>
                <label>primary - normal</label>
                <CheckBox type={'primary'} size={'normal'} value={control} setValue={setControl}/> <br/>
                <label>primary - large</label>
                <CheckBox type={'primary'} size={'large'} value={control} setValue={setControl}/> <br/>
                <label>secondary - normal</label>
                <CheckBox type={'secondary'} size={'normal'} value={control} setValue={setControl}/> <br/>
                <label>secondary - large</label>
                <CheckBox type={'secondary'} size={'large'} value={control} setValue={setControl}/>

                <br/>
                <h1>Radio</h1>
                <label>primary - normal</label>
                <Radio type={'primary'} size={'normal'} value={control} setValue={setControl}/> <br/>
                <label>primary - large</label>
                <Radio type={'primary'} size={'large'} value={control} setValue={setControl}/> <br/>
                <label>secondary - normal</label>
                <Radio type={'secondary'} size={'normal'} value={control} setValue={setControl}/> <br/>
                <label>secondary - large</label>
                <Radio type={'secondary'} size={'large'} value={control} setValue={setControl}/>

                <br/>
                <h1>Controls</h1>
                <label>Small - CheckBox</label>
                <Controls title={"동의하십니까?"} type={'checkbox'} size={'small'} value={control} setValue={setControl}/>
                <label>Small - CheckBox - icon</label>
                <Controls title={"동의하십니까?"} type={'checkbox'} size={'small'} icon={'add_circle_outline'} value={control} setValue={setControl}/>

                <label>Small - Radio - icon</label>
                <Controls title={"동의하십니까?"} type={'radio'} size={'small'} value={control} setValue={setControl}/>

                <label>Small - Radio - icon</label>
                <Controls title={"동의하십니까?"} type={'radio'} size={'small'} icon={'add_circle_outline'} value={control} setValue={setControl}/>

                <label>Large - Toggle - icon</label>
                <Controls title={"동의하십니까?"} type={'toggle'} size={'large'} value={control} setValue={setControl}/>

                <label>Large - Toggle - icon</label>
                <Controls title={"동의하십니까?"} type={'toggle'} size={'large'} icon={'add_circle_outline'} value={control} setValue={setControl}/>

                <br/>

                <h1>Dropdown</h1>
                <div>
                    <Dropdown placeholder={"small size"} optionList={[
                        {
                            id: 1,
                            title: "옵션1"
                        },
                        {
                            id: 2,
                            title: "옵션2"
                        }
                    ]} state={selected} setState={setSelected} size={"small"} eyebrow={"eyebrow"} eyebrowRequired={true} caption={"caption"}/>

                    <Dropdown placeholder={"large size"} optionList={[
                        {
                            id: 1,
                            title: "옵션1"
                        },
                        {
                            id: 2,
                            title: "옵션2"
                        }
                    ]} state={selected} setState={setSelected} size={"large"} rounded={true} caption={"caption"}/>

                    <Dropdown placeholder={"large size"} optionList={[
                        {
                            id: 1,
                            title: "옵션1"
                        },
                        {
                            id: 2,
                            title: "옵션2"
                        }
                    ]} state={selected} setState={setSelected} size={"large"} rounded={true} caption={"caption"} eyebrow={"eyebrow"} disabled={true}/>
                </div>

                <br/>

                <h1>InputBox</h1>
                <label>기본 입력</label>
                <br/>

                <InputBox size={'medium'} value={data1} placeholder="반려인의 휴대폰 번호를" events={{
                    onChange: data => setData1(data),
                }}/> <InputBox value={'이신혜'} events={{
                onChange: data => setData1(data),
            }} disabled={true}/> <InputBox value={data1} placeholder="반려인의 휴대폰 번호를" events={{
                onChange: data => setData1(data),
            }} confirm={true}/>

                <InputBox size={'medium'} value={data1} placeholder="반려인의 휴대폰 번호를" events={{
                    onChange: data => setData1(data),
                }} error={true}/>


                <InputBox size={'large'} value={data1} placeholder="반려인의 휴대폰 번호를" events={{
                    onChange: data => setData1(data),
                }} confirm={true}/>


                <InputBox title={'제목을'} size={'medium'} value={data1} placeholder="반려인의 휴대폰 번호를" events={{
                    onChange: data => setData1(data),
                }} comment={'이건 반칙'}/>

                <InputBox title={'제목을'} size={'medium'} value={data1} placeholder="반려인의 휴대폰 번호를" events={{
                    onChange: data => setData1(data),
                }} comment={'이건 반칙'} error={true}/>

                <h1>TextBox</h1>
                <label>기본 입력</label>
                <br/>

                <TextBox title={'textBox medium'} size={'medium'} value={data1} placeholder="textBox medium" events={{
                    onChange: data => setData1(data),
                }}/>

                <TextBox title={'textBox large'} size={'large'} value={data1} placeholder="textBox large" events={{
                    onChange: data => setData1(data),
                }}/> <TextBox size={'medium'} value={data1} placeholder="textBox medium error" events={{
                onChange: data => setData1(data),
            }} comment={'이건 반칙'} error={true}/> <TextBox size={'medium'} value={data1} placeholder="textBox large disabled" events={{
                onChange: data => setData1(data),
            }} disabled={true}/>

                <br/>

                <div>
                    <p>Buttons</p>
                    <Buttons value={"default extra_small"} size={"extra_small"} type={"default"} icon={"add_circle_outline"} onClick={() => {
                        //console.log('hi')
                    }}/> <Buttons value={"tint small"} size={"small"} type={"tint"} icon={"add_circle_outline"} onClick={() => {
                    // console.log('hi')
                }}/> <Buttons value={"elevated medium"} size={"medium"} type={"elevated"} icon={"add_circle_outline"} onClick={() => {
                    //console.log('hi')
                }}/> <Buttons value={"outlined large"} size={"large"} type={"outlined"} icon={"add_circle_outline"} onClick={() => {
                    //console.log('hi')
                }}/> <Buttons value={"outlined large"} size={"large"} type={"outlined"} icon={"add_circle_outline"} disabled={true} onClick={() => {
                    //console.log('hi')
                }}/> <Buttons value={"text large"} size={"large"} type={"text"} icon={"add_circle_outline"} onClick={() => {
                    //console.log('hi')
                }}/>
                </div>

                <br/>

                <div>
                    <p>Bottom Sheets(click ↓)</p>
                    <Buttons value={"open bottom sheet 1"} size={"extra_small"} type={"default"} onClick={() => {
                        setOpenBottomSheet1(true)
                    }}/> <Buttons value={"open bottom sheet 2"} size={"extra_small"} type={"default"} onClick={() => {
                    setOpenBottomSheet2(true)
                }}/>
                </div>

                <br/>

                <div>
                    <p>Chips</p>
                    <Chips value={"default small"} size={"small"} type={"default"} icon_left={"account_circle"} icon_right={"close"} onClick={() => (console.log("chips!"))}/> <Chips value={"tint medium"} size={"medium"} type={"tint"} icon_left={"check"} onClick={() => (console.log("chips!"))}/>
                    <Chips value={"elevated large"} size={"large"} type={"elevated"} icon_right={"close"} onClick={() => (console.log("chips!"))}/> <Chips value={"outlined small"} size={"small"} type={"outlined"} icon_left={"account_circle"} icon_right={"close"} onClick={() => (console.log("chips!"))}/>
                </div>

                <br/>

                <div>
                    <p>Tooltips</p>
                    <Tooltips value={"small up"} size={"small"} arrow={"up"} onClick={() => (console.log("chips!"))}/> <Tooltips value={"small down"} size={"small"} arrow={"down"} icon_left={"account_circle"} icon_right={"chevron_right"} onClick={() => (console.log("chips!"))}/>
                    <Tooltips value={"medium left"} size={"medium"} arrow={"left"} icon_left={"account_circle"} icon_right={"chevron_right"} onClick={() => (console.log("chips!"))}/> <Tooltips value={"large right: There can be Two lines"} size={"large"} arrow={"down"} icon_left={"account_circle"} icon_right={"chevron_right"} onClick={() => (console.log("chips!"))}/>
                    <Tooltips value={"large right: There can be Two lines"} size={"large"} arrow={"right"} icon_left={"account_circle"} icon_right={"chevron_right"} onClick={() => (console.log("chips!"))}/>
                </div>

                <br/>

                <div>
                    <p>Modal(click ↓)</p>
                    <Buttons value={"open modal 1"} size={"extra_small"} type={"default"} onClick={() => {
                        setOpenModal1(true)
                    }}/> <Buttons value={"open modal 2"} size={"extra_small"} type={"default"} onClick={() => {
                    setOpenModal2(true)
                }}/>
                </div>

                <br/>

                <div>
                    <p>Snack Bar(animation)</p>
                    <SnackBar title={"Snack bar with a single line"} icon={"add_circle"} showSnack={showSnack} setShowSnack={setShowSnack} button={{
                        type: "text",
                        value: "Button",
                        onClick: () => console.log("snack")
                    }}/> <SnackBar title={"Snack bar with a single line"} icon={"add_circle"} showSnack={showSnack} setShowSnack={setShowSnack} button={{
                    type: "icon",
                    value: "arrow_forward",
                    onClick: () => console.log("snack")
                }}/>
                </div>

                <br/>

                <div>
                    <p>Tags</p>
                    <Tags color={"default"} dark={true} text={"방문 예정"}/> <Tags color={"default"} text={"방문 예정"}/> <Tags color={"yellow"} dark={true} text={"방문 예정"}/> <Tags color={"yellow"} text={"방문 예정"}/> <Tags color={"blue"} dark={true} text={"방문 예정"}/> <Tags color={"blue"} text={"방문 예정"}/> <Tags color={"green"} dark={true} text={"방문 예정"}/> <Tags color={"green"} text={"방문 예정"}/>
                    <Tags color={"red"} dark={true} text={"방문 예정"}/> <Tags color={"red"} text={"방문 예정"}/>
                </div>

                <br/>

                <div>
                    <p>List</p>
                    <ul>
                        <List title={"둥둥이"} button={true}/> <List title={"둥둥이"} desc={"비숑 하이바컷 (디자인비용)"} button={true}/> <List title={"둥둥이"} desc={"비숑 하이바컷 (디자인비용)"} status={"R18002"} button={true}/> <List title={"둥둥이"} desc={"비숑 하이바컷 (디자인비용)"} status={"R18002"} date={"2021-12-30"} time={"15:25"} button={true}/>
                        <List title={"둥둥이"} desc={"비숑 하이바컷 (디자인비용)"} status={"R18002"} tagInline={true} button={true}/> <List title={"둥둥이"} desc={"비숑 하이바컷 (디자인비용)"} status={"R18002"} tagInline={true} date={"2021-12-30"} time={"15:25"} button={true}/>
                    </ul>
                </div>

                <br/>

                <div>
                    <p>GreenList</p>
                    <GreenList dataArr={[
                        {
                            label: "예약 번호",
                            content: "12341234"
                        },
                        {
                            label: "예약 일시",
                            content: "2022-02-03 / 10:00"
                        },
                        {
                            label: "예약 상태",
                            status: "R18001"
                        },
                        {
                            label: "업체명",
                            content: "리카네 그루밍"
                        },
                        {
                            label: "서비스 정보",
                            content: "비숑 하이바컷"
                        },
                        // {
                        //     label: "예상결제금액",
                        //     content: 200000
                        // },
                        {
                            label: "예약금",
                            content: 13000
                        },
                    ]}/>
                </div>

                <br/>

                <div style={{margin: "0 -24px"}}>
                    <p>Tab (container margin "0 -24px")</p>
                    <Tabs size={"small"} tabs={[
                        {
                            label: "탭하나",
                            pathname: "/components/step1"
                        },
                        {
                            label: "탭둘",
                            pathname: "/components/step2"
                        }
                    ]}/>

                    <Tabs size={"medium"} tabs={[
                        {
                            label: "탭하나",
                            pathname: "/components/step1"
                        },
                        {
                            label: "탭둘",
                            pathname: "/components/step2"
                        },
                        {
                            label: "탭셋",
                            pathname: "/components/step3"
                        },
                    ]}/>

                    <Tabs size={"large"} tabs={[
                        {
                            label: "탭하나",
                            pathname: "/components/step1"
                        },
                        {
                            label: "탭둘",
                            pathname: "/components/step2"
                        },
                        {
                            label: "탭셋",
                            pathname: "/components/step3"
                        },
                        {
                            label: "탭넷",
                            pathname: "/components/step4"
                        },
                    ]}/>

                    {/*router children*/} <Outlet/>

                    <br/>

                    <p>FillTab</p>
                    <FillTabs size={"small"} selected={selectedTab} setSelected={setSelectedTab} tabs={[
                        {
                            label: "탭하나",
                            value: "one"
                        },
                        {
                            label: "탭둘",
                            icon: "account_circle",
                            value: "two"
                        },
                        {
                            label: "탭셋",
                            image: "/image/checker_board.png",
                            value: "three"
                        },
                        {
                            label: "탭넷",
                            image: "/image/checker_board.png",
                            value: "four"
                        },
                        {
                            label: "탭다섯",
                            image: "/image/checker_board.png",
                            value: "five"
                        },
                        {
                            label: "탭여섯",
                            icon: "account_circle",
                            value: "six"
                        },
                        {
                            label: "탭일곱",
                            icon: "account_circle",
                            value: "seven"
                        },
                        {
                            label: "탭여덟",
                            icon: "account_circle",
                            value: "eight"
                        },
                        {
                            label: "탭아홉",
                            icon: "account_circle",
                            value: "nine"
                        },
                        {
                            label: "탭열",
                            icon: "account_circle",
                            value: "ten"
                        },
                    ]}/>

                    <FillTabs size={"medium"} selected={selectedTab} setSelected={setSelectedTab} tabs={[
                        {
                            label: "탭하나",
                            value: "one"
                        },
                        {
                            label: "탭둘",
                            icon: "account_circle",
                            value: "two"
                        },
                        {
                            label: "탭셋",
                            image: "/image/checker_board.png",
                            value: "three"
                        },
                        {
                            label: "탭넷",
                            image: "/image/checker_board.png",
                            value: "four"
                        },
                        {
                            label: "탭다섯",
                            image: "/image/checker_board.png",
                            value: "five"
                        },
                        {
                            label: "탭여섯",
                            icon: "account_circle",
                            value: "six"
                        },
                        {
                            label: "탭일곱",
                            icon: "account_circle",
                            value: "seven"
                        },
                        {
                            label: "탭여덟",
                            icon: "account_circle",
                            value: "eight"
                        },
                        {
                            label: "탭아홉",
                            icon: "account_circle",
                            value: "nine"
                        },
                        {
                            label: "탭열",
                            icon: "account_circle",
                            value: "ten"
                        },
                    ]}/>
                </div>
            </div>
        </div>

        {openBottomSheet1 &&
            <BottomSheet close={true} slider={true} header={"Header Title 1"} setOpen={setOpenBottomSheet1} buttons={[
                {
                    title: "Single",
                    onClick: () => {
                        setOpenBottomSheet1(false)
                    }
                }
            ]}>
                <div className="subheader_16pt">
                    서버에서 보내주는<br/> 메시지에 따라<br/> 텍스트가 달라질 수 있습니다.
                </div>
            </BottomSheet>}

        {openBottomSheet2 &&
            <BottomSheet close={true} slider={true} header={"Header Title 2"} setOpen={setOpenBottomSheet2} buttons={[
                {
                    title: "Left",
                    onClick: () => {
                        setOpenBottomSheet2(false)
                    }
                },
                {
                    title: "Right",
                    onClick: () => {
                        setOpenBottomSheet2(false)
                    }
                }
            ]}>
                <div className="subheader_16pt">
                    서버에서 보내주는<br/> 메시지에 따라<br/> 텍스트가 달라질 수 있습니다.
                </div>
            </BottomSheet>}

        {openModal1 &&
            <Modal close={true} header={"Header Title 1"} headerStyle={"default"} setOpen={setOpenModal1} contentAlign={"left"} content={`<div>
                   서버에서 보내주는<br/>
                   메시지에 따라<br/>
                   텍스트가 달라질 수 있습니다.
               </div>`} buttonStyle={"upDown"} buttons={[
                {
                    title: "Up",
                    onClick: () => {
                        setOpenModal1(false)
                    }
                },
                {
                    title: "Down",
                    onClick: () => {
                        setOpenModal1(false)
                    }
                }
            ]}> </Modal>}

        {openModal2 &&
            <Modal setOpen={setOpenModal2} header={"Header Title 2"} headerStyle={"accent"} contentAlign={"center"} content={`<div>
                   서버에서 보내주는<br/>
                   메시지에 따라<br/>
                   텍스트가 달라질 수 있습니다.
               </div>`} buttonStyle={"default"} buttons={[
                {
                    title: "left",
                    onClick: () => {
                        setOpenModal2(false)
                    }
                },
                {
                    title: "right",
                    onClick: () => {
                        setOpenModal2(false)
                    }
                }
            ]}> </Modal>}

        <BottomButtons buttons={[
            {
                title: "취소",
                onClick: () => console.log("취소")
            },
            {
                title: "저장",
                onClick: () => console.log("저장")
            }
        ]}/>
    </>)
}


export function Step1() {

    const navigate = useNavigate();
    //쿼리스티링 파라미터 받기
    //http://localhost:3000/join?username=홍길동
    let [searchParams] = useSearchParams();
    let username = searchParams.get("username");
    console.log(username)

    function handleClick() {
        navigate("../step2", {state: {id: 1, name: '엔드류 스트워트 톰 로빈슨'}});
    }

    return (
        <div>
            <h1>가입화면 첫번째</h1>
            <button onClick={handleClick}>다음으로</button>
        </div>
    )
}

export function Step2() {

    const navigate = useNavigate();
    //쿼리스티링 파라미터 받기
    //http://localhost:3000/join?username=홍길동
    let [searchParams] = useSearchParams();
    let username = searchParams.get("username");
    console.log(username)

    function handleClick() {
        navigate("../step3", {state: {id: 1, name: '엔드류 스트워트 톰 로빈슨'}});
    }

    return (
        <div>
            <h1>가입화면 두번째</h1>
            <button onClick={handleClick}>다음으로</button>
        </div>
    )
}

export function Step3() {

    const navigate = useNavigate();
    //쿼리스티링 파라미터 받기
    //http://localhost:3000/join?username=홍길동
    let [searchParams] = useSearchParams();
    let username = searchParams.get("username");
    console.log(username)

    function handleClick() {
        navigate("../../login", {state: {id: 1, name: '엔드류 스트워트 톰 로빈슨'}});
    }

    return (
        <div>
            <h1>가입화면 마지막</h1>
            <button onClick={handleClick}>로그인 화면</button>
        </div>
    )
}
