export const numberWithCommas = (x) => {
    x = x + ''
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const today = () => {
    const today = new Date()
    const year = today.getFullYear().toString()
    const month = ('0' + (today.getMonth() + 1)).slice(-2)
    const day = ('0' + today.getDate()).slice(-2)

    return {year, month, day}
}

function convertDate(start, end) {
    const temp = []
    for (; start <= end; start++) {
        if (start < 10) {
            temp.push(`0${start}`)
        }
        else {
            temp.push(`${start}`)
        }
    }
    return temp;
}

export const limitMonth = (today, year, month) => {
    let items = []

    items = convertDate(Number(today.month), 12)

    if (Number(year) > Number(today.year)) {
        items = convertDate(1, 12)
    }

    return items;
}

export const limitDay = (today, year, month) => {
    const lastDay = new Date(year, month, 0)
    lastDay.getDate()

    let items = []
    items = convertDate(1, lastDay.getDate())

    if (Number(year) === Number(today.year) && Number(month) === Number(today.month)) {
        items = convertDate(Number(today.day), lastDay.getDate())
    }

    return items;
}


export const extraHoliday = (data, year, month, dayItems) => {
    const extras = data?.extraHolidays
    const workDays = data?.workDays
    const allRegularHolidays = data?.allRegularHolidays
    let temp3 = []
    if (extras !== undefined) {
        const temp = extras.filter(it => {
            return it.startDt.split('-')[0] === year && it.startDt.split('-')[1] === month
        })
        let temp1 = []
        temp.map((it) => {
            const start = it.startDt.split('-')[2]
            const end = it.endDt.split('-')[2]
            for (let i = Number(start); i <= Number(end); i++) {
                temp1.push((('0' + (i))).slice(-2))
            }
        })

        temp3 = dayItems.filter(item => !temp1.includes(item));
    }
    if (workDays !== undefined) {
        let day = temp3.filter(item => {
            const week = getWeek(year, month, item)
            if (workDays.includes(week)) {
                return item
            }
        })
        temp3 = day
    }
    if (allRegularHolidays !== undefined) {
        let regular = []
        allRegularHolidays.map(r => {
            const temp = convertRegularHoliday(r.period, r.dayWeek, Number(year), Number(month))
            regular.push(...temp)
        });
        regular.sort((a, b) => a - b)
        temp3 = temp3.filter(item => !regular.includes(Number(item)))
    }

    return temp3
}

function getWeek(y, m, d) {
    let day = new Date(y, m, d);
    const WEEKDAY = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return WEEKDAY[day.getDay()];
}

function convertRegularHoliday(code, week, year, month) {
    const everyWeek = Number(code.slice(-1) - 1);
    const last = new Date(year, month, 0).getDate()
    let arr = []
    for (let x of range(1, last)) {
        const y = weekNumberByMonth(`${year}-${month}-${x}`)

        if (y.weekNo === everyWeek) {
            if (getWeek(year, month, x) === week) {
                arr.push(x)
            }
        }
        else if (everyWeek === 0) {
            if (getWeek(year, month, x) === week) {
                arr.push(x)
            }
        }
    }
    return arr
}

function range(start, end, step, offset) {

    const len = (Math.abs(end - start) + ((offset || 0) * 2)) / (step || 1) + 1;
    const direction = start < end ? 1 : -1;
    let startingPoint = start - (direction * (offset || 0));
    let stepSize = direction * (step || 1);

    return Array(len).fill(0).map(function (_, index) {
        return startingPoint + (stepSize * index);
    });
}

function weekNumberByMonth(dateFormat) {
    const inputDate = new Date(dateFormat)
    let year = inputDate.getFullYear()
    let month = inputDate.getMonth() + 1

    const weekNumberBySun = (paramDate) => {

        const year = paramDate.getFullYear()
        const month = paramDate.getMonth()
        const date = paramDate.getDate()

        const firstDate = new Date(year, month, 1)
        const lastDate = new Date(year, month + 1, 0)
        const firstDayOfWeek = firstDate.getDay() === 0 ? 7 : firstDate.getDay()
        const lastDayOfweek = lastDate.getDay()

        const lastDay = lastDate.getDate()

        const firstWeekCheck = firstDayOfWeek === 1 || firstDayOfWeek === 2 || firstDayOfWeek === 3
        const lastWeekCheck = lastDayOfweek === 5 || lastDayOfweek === 6 || lastDayOfweek === 7

        const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay) / 7)
        let weekNo = Math.ceil((firstDayOfWeek - 1 + date) / 7)

        if (weekNo === 1 && firstWeekCheck) {
            weekNo = 'prev'
        }
        else if (weekNo === lastWeekNo && lastWeekCheck) {
            weekNo = 'next'
        }
        else if (firstWeekCheck) {
            weekNo = weekNo - 1
        }

        return weekNo;
    };

    let weekNo = weekNumberBySun(inputDate);

    if (weekNo === 'prev') {
        const afterDate = new Date(year, month - 1, 0);
        year = month === 1 ? year - 1 : year;
        month = month === 1 ? 12 : month - 1;
        weekNo = weekNumberBySun(afterDate);
    }
    if (weekNo === 'next') {
        year = month === 12 ? year + 1 : year;
        month = month === 12 ? 1 : month + 1;
        weekNo = 1;
    }

    return {year, month, weekNo};
}
