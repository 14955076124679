import React from "react";
import style from "../css/module/chip.module.scss"

export default function Chips(props) {
    const {size, type, value, onClick, icon_left, icon_right} = props

    const getFontSize = () => {
        if (size === "small") {
            return "subheader_14pt"
        }
        else if (size === "medium" || size === "large") {
            return "subheader_16pt"
        }
    }

    const defaultProps = {
        className: `${style.chip} ${style[type]} ${style[size]} ${getFontSize()}`,
        onClick: onClick
    }

    return (
        <div {...defaultProps}>
            {icon_left && <span className={`material-icons-round ${style.icon} ${style.icon_left}`}>{icon_left}</span>} {value} {icon_right && <span className={`material-icons-round ${style.icon} ${style.icon_right}`}>{icon_right}</span>}
        </div>
    )
}
