import React from "react";
import style from "../css/module/dropdownButton.module.scss";

export default function DropdownButton(props) {
    const {
        eyebrow,
        eyebrowRequired,
        caption,
        optionList,
        events,
        placeholder,
        rounded,
        size = "small",
        disabled
    } = props

    const handleChange = (e) => {
        e.preventDefault()
        //console.log(e.target.value)

    }

    const onClick = (e) => {
        e.preventDefault()
        events(e)
    }

    return (<>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]} ${eyebrow && style.eyebrow_container} ${disabled && style.disabled}`}>
                {eyebrow && <label className={`${style.eyebrow} ${eyebrowRequired ? style.required : ""} caption_12pt_medium`}>{eyebrow}</label>}
                <button className={`headline_18pt`} onClick={(e) => onClick(e)}>
                    {placeholder}
                </button>
                <span className={`material-icons-round ${style.arrow}`}>expand_more</span>
            </div>
            {caption && <p className={`${style.caption} caption_12pt_medium`}>{caption}</p>}
        </>
    )
}
