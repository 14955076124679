import React, {useEffect, useState} from "react";
import '../css/module/custom-rc-time-picker.css';
import style from "../css/module/dropdownTimePicker.module.scss";

function convert(limit) {
    const temp = []

    for (let i = 1; i <= limit; i++) {
        if (i === 1 && limit === 60) {
            temp.push("00")
        }
        if (i < 10) {
            temp.push(`0${i}`)
        }
        else {
            temp.push(`${i}`)
        }
    }
    return temp;
}

function countTime(start, end) {
    const temp = []
    start = parseInt(start, 10)
    end = parseInt(end, 10)
    for (; start <= end; start++) {
        if (start < 10) {
            temp.push(`0${start}`)
        }
        else {
            temp.push(`${start}`)
        }
    }
    return temp;
}

function getWeek(date) {
    let dates = date.split("-")
    let day = new Date(dates[0], dates[1], dates[2]);
    const WEEKDAY = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return WEEKDAY[day.getDay()];
}

export default function DropdownTimePicker(props) {
    const {
        rounded,
        disabled,
        size = "small",
        events,
        date,
        extra
    } = props

    const [meridiem, setMeridiem] = useState("AM")
    const [hour, setHour] = useState("09")
    const [min, setMin] = useState("00")

    const [startMer, setStartMer] = useState('AM')
    const [startHour, setStartHour] = useState('09')
    const [startMin, setStartMin] = useState('00')

    const [endMer, setEndMer] = useState('PM')
    const [endHour, setEndHour] = useState('09')
    const [endMin, setEndMin] = useState('00')

    const week = getWeek(date)
    useEffect(() => {
        if (extra !== null) {
            const dayWeeks = extra.dayWeeks

            let works = []

            dayWeeks.map(it => {
                it.dayWeeks.map(it1 => {
                    if (it1 === week) {
                        works = it
                    }
                })
            })
            let startAt = '09:00:00'
            let endAt = '18:00:00'
            if (Object.keys(works).length > 0) {
                startAt = works.work.startAt
                endAt = works.work.endAt
            }

            const start = startAt.split(':')
            const end = endAt.split(':')

            if (Number(start[0]) < 12) {
                setStartMer('AM')
                setMeridiem('AM')
            }
            else {
                setStartMer('PM')
                setMeridiem('PM')
            }

            setStartHour(start[0])
            setStartMin(start[1])
            setEndHour(Number(end[0] - 12))
            setEndMin(end[1])
        }

    }, [date, extra])

    let hour_items = countTime(startHour, 11)
    if (meridiem === 'PM') {
        hour_items = countTime(1, endHour)
        if (startHour === '00') {
            hour_items.unshift('12')
        }
    }

    let min_items = countTime(0, 59)
    if (meridiem === 'AM' && startHour === hour) {
        min_items = countTime(startMin, 59)
    }
    else if (meridiem === 'PM' && endHour === hour) {
        min_items = countTime(0, endMin)
    }


    const {onChange} = events;


    useEffect(() => {
        onChange(
            meridiem + " " + hour + ":" + min
        );
    }, [meridiem, hour, min])

    return (<div className={`${style.dropdown_wrap}`}>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]}  ${disabled && style.disabled}`}>
                <select className={`headline_18pt `} value={meridiem} onChange={(e) => setMeridiem(e.target.value)}>
                    {startMer === 'AM' &&
                        <option value={"AM"}>오전</option>
                    }
                    <option value={"PM"}>오후</option>
                </select>
                <span className={`material-icons-round ${style.arrow}`}>expand_more</span>
            </div>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]}  ${disabled && style.disabled}`}>
                <select className={`headline_18pt `} value={hour} onChange={(e) => setHour(e.target.value)}>
                    {hour_items.map((value, index) => {
                        return <option key={index} value={value}>{value}</option>
                    })}
                </select>
                <span className={`material-icons-round ${style.arrow}`}>expand_more</span>
            </div>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]}  ${disabled && style.disabled}`}>
                <select className={`headline_18pt `} value={min} onChange={(e) => setMin(e.target.value)}>
                    {min_items.map((value, index) => {
                        return <option key={index} value={value}>{value}</option>
                    })}
                </select>
                <span className={`material-icons-round ${style.arrow}`}>expand_more</span>
            </div>
        </div>
    )
}
