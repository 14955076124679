import React, {useEffect} from 'react';

export default function Landing() {

    useEffect(() => {
        window.location.href = "https://www.pawdly.com"
    })

    return (
        <></>
    )
}
