import React, {useEffect, useState} from "react";
import style from "../css/module/bottomModalProfileRadio.module.scss"
import Buttons from "./Buttons";
import useScrollDetact from "../modules/useScrollDetact";

export default function BottomModalProfileRadio(props) {

    const {
        close,
        header,
        headerStyle = "default",
        buttons,
        buttonStyle = "default",
        setOpen,
        content,
        contentAlign = "center",
        value,
        setValue,
        title,
        setTitle,
        setCurrentPage
    } = props
    console.log(content)
    const [managers, setManagers] = useState([]);
    const page = content.pagination
    const [currentPage] = useScrollDetact(page.currentPage, page.totalPage)

    useEffect(() => {
        console.log('현재 페이지', currentPage)

        setCurrentPage(currentPage)
    }, [currentPage])

    function selectedRadio(code, title) {
        setValue(code)
        setTitle(title)
    }

    useEffect(() => {
        const obj = content.result
        let mergeManager = [...managers, ...obj]

        setManagers(mergeManager)

    }, [content])


    const renderButtons = (buttons) => {
        if (buttons.length === 1) {
            return (
                <div className={`${style.buttons} ${style.default}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"default"} onClick={buttons[0].onClick}/>
                </div>
            )
        }
        else if (buttonStyle === "upDown" && buttons.length === 2) {
            return (
                <div className={`${style.buttons} ${style.upDown}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"default"} onClick={buttons[0].onClick}/> <Buttons value={buttons[1].title} size={"extra_small"} type={"text"} onClick={buttons[1].onClick}/>
                </div>
            )
        }
        else if (buttonStyle === "default" && buttons.length === 2) {
            return (
                <div className={`${style.buttons} ${style.default}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"text"} onClick={buttons[0].onClick}/> <Buttons value={buttons[1].title} size={"extra_small"} type={"default"} onClick={buttons[1].onClick}/>
                </div>
            )
        }
    }

    return (
        <div className={style.background}>
            <div className={`${style.modal} modal`}>

                <div className={style.header}>
                    {header && <p className={`${style[headerStyle]} headline_24pt`} dangerouslySetInnerHTML={{__html: header}}/>} {close && <span onClick={() => {
                    setValue("")
                    setTitle("아무나 괜찮아요")
                    setOpen(false)
                }} className="material-icons-round">close</span>}
                </div>
                <div className={`${style.controls_radio} ${style.bg_transparent}`} onClick={(e) => selectedRadio('', '아무나 괜찮아요')}>
                    <div className={`${style.title} headline_18pt`}>아무나 괜찮아요</div>
                    <div className={`${style.toggle}  ${'' === value && style.on}`} value={''}>
                        <div className={style.circle}/>
                    </div>
                </div>
                <div className={style.line}/>


                {content && <div className={`${style.content} ${style[contentAlign]} subheader_14pt`}>
                    {managers.map((obj, index) => {
                        return <div key={index} className={`${style.controls_radio} mb10`} onClick={(e) => selectedRadio(obj.mbId, obj.name)}>
                            <div className={`${style.title} headline_18pt`}>
                                <img src={process.env.REACT_APP_DATA_API + obj.imageUrl}/>
                                {obj.name}
                            </div>
                            <div className={`${style.toggle}  ${obj.mbId === value && style.on}`} value={obj.mbId}>
                                <div className={style.circle}/>
                            </div>
                        </div>
                    })}
                </div>}

                {buttons && renderButtons(buttons)}
            </div>
        </div>
    )
}
