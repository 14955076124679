import axiosInstance from "../../modules/network";

export const AgreementFetch = () => {
    const visitAgree = async (params) => {
        try {
            const result = await axiosInstance.post('/api/user/reservation/reply-visit', params)
            return result
        } catch (error) {
            return error.response
        }
    }
    return {visitAgree}
}
