import React, {useEffect, useState} from "react";
import '../css/module/custom-rc-time-picker.css';
import style from "../css/module/dropdownDatePicker.module.scss";

function convertDate(start, end) {
    const temp = []
    for (; start <= end; start++) {
        if (start < 10) {
            temp.push(`0${start}`)
        }
        else {
            temp.push(`${start}`)
        }
    }
    return temp;
}

function getTodate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    return {year, month, day}
}

function getItems(pYear, pMonth) {
    const {year, month, day} = getTodate()

    const year_items = year

    let month_items = convertDate(Number(month), 12)
    if (pYear > year) {
        month_items = convertDate(1, 12)
    }

    const lastday = new Date(pYear, pMonth, 0)
    lastday.getDate()

    let day_items = convertDate(1, lastday.getDate())
    if (pYear === year && pMonth === month) {
        day_items = convertDate(Number(day), lastday.getDate())
    }

    return {year_items, month_items, day_items}
}

function getWeek(y, m, d) {
    let day = new Date(y, m, d);
    const WEEKDAY = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return WEEKDAY[day.getDay()];
}

function weekNumberByMonth(dateFormat) {
    const inputDate = new Date(dateFormat)
    let year = inputDate.getFullYear()
    let month = inputDate.getMonth() + 1

    const weekNumberBySun = (paramDate) => {

        const year = paramDate.getFullYear()
        const month = paramDate.getMonth()
        const date = paramDate.getDate()

        const firstDate = new Date(year, month, 1)
        const lastDate = new Date(year, month + 1, 0)
        const firstDayOfWeek = firstDate.getDay() === 0 ? 7 : firstDate.getDay()
        const lastDayOfweek = lastDate.getDay()

        const lastDay = lastDate.getDate()

        const firstWeekCheck = firstDayOfWeek === 1 || firstDayOfWeek === 2 || firstDayOfWeek === 3
        const lastWeekCheck = lastDayOfweek === 5 || lastDayOfweek === 6 || lastDayOfweek === 7

        const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay) / 7)
        let weekNo = Math.ceil((firstDayOfWeek - 1 + date) / 7)

        if (weekNo === 1 && firstWeekCheck) {
            weekNo = 'prev'
        }
        else if (weekNo === lastWeekNo && lastWeekCheck) {
            weekNo = 'next'
        }
        else if (firstWeekCheck) {
            weekNo = weekNo - 1
        }

        return weekNo;
    };

    let weekNo = weekNumberBySun(inputDate);

    if (weekNo === 'prev') {
        const afterDate = new Date(year, month - 1, 0);
        year = month === 1 ? year - 1 : year;
        month = month === 1 ? 12 : month - 1;
        weekNo = weekNumberBySun(afterDate);
    }
    if (weekNo === 'next') {
        year = month === 12 ? year + 1 : year;
        month = month === 12 ? 1 : month + 1;
        weekNo = 1;
    }

    return {year, month, weekNo};
}

function range(start, end, step, offset) {

    const len = (Math.abs(end - start) + ((offset || 0) * 2)) / (step || 1) + 1;
    const direction = start < end ? 1 : -1;
    let startingPoint = start - (direction * (offset || 0));
    let stepSize = direction * (step || 1);

    return Array(len).fill(0).map(function (_, index) {
        return startingPoint + (stepSize * index);
    });
}


function convertRegularHoliday(code, week, year, month) {
    const everyWeek = Number(code.slice(-1) - 1);
    const last = new Date(year, month, 0).getDate()
    let arr = []
    for (let x of range(1, last)) {
        const y = weekNumberByMonth(`${year}-${month}-${x}`)

        if (y.weekNo === everyWeek) {
            if (getWeek(year, month, x) === week) {
                arr.push(x)
            }
        }
        else if (everyWeek === 0) {
            if (getWeek(year, month, x) === week) {
                arr.push(x)
            }
        }
    }
    return arr
}

export default function DropdownDatePicker(props) {
    let {
        date,
        rounded,
        disabled,
        size = "small",
        events,
        extra
    } = props

    let extraHolidays = extra?.extraHolidays
    if (date === null || date === '') {
        const {year, month, day} = getTodate()
        date = year + '-' + month + '-' + day
    }
    let dates = date.split("-")

    const [year, setYear] = useState(dates[0])
    const [month, setMonth] = useState(('0' + (Number(dates[1]))).slice(-2))
    const [day, setDay] = useState(('0' + (Number(dates[2]))).slice(-2))
    const {year_items, month_items, day_items} = getItems(year, month)
    const [extraDay, setExtraDay] = useState([])
    const {onChange} = events

    useEffect(() => {
        onChange(
            year + "-" + month + "-" + day
        );
    }, [year, month, day])

    useEffect(() => {
        if (extraHolidays !== null) {
            const temp = extraHolidays.filter(it => {
                return it.startDt.split('-')[0] === year && it.startDt.split('-')[1] === month
            })
            let temp1 = []
            temp.map((it) => {
                const start = it.startDt.split('-')[2]
                const end = it.endDt.split('-')[2]
                for (let i = Number(start); i <= Number(end); i++) {
                    temp1.push((('0' + (i))).slice(-2))
                }
            })

            let temp3 = day_items.filter(item => !temp1.includes(item));

            let workDays = extra?.workDays

            let day = temp3.filter(item => {
                const week = getWeek(year, month, item)
                if (workDays.includes(week)) {
                    return item
                }
            })


            const regularHoliDay = extra.allRegularHolidays

            let regular = []

            regularHoliDay.map(r => {
                const temp = convertRegularHoliday(r.period, r.dayWeek, Number(year), Number(month))
                regular.push(...temp)
            });
            regular.sort((a, b) => a - b)
            day = day.filter(item => !regular.includes(Number(item)))
            setExtraDay(day)
        }
    }, [extra, year, month])


    return (<div className={`${style.dropdown_wrap}`}>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]}  ${disabled && style.disabled}`}>
                <select className={`headline_18pt `} value={year} onChange={(e) => setYear(e.target.value)}>
                    <option value={year_items}>{year_items}년</option>
                    <option value={Number(year_items) + 1}>{Number(year_items) + 1}년</option>
                </select>
                <span className={`material-icons-round ${style.arrow}`}>expand_more</span>
            </div>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]}  ${disabled && style.disabled}`}>
                <select className={`headline_18pt `} value={month} onChange={(e) => setMonth(e.target.value)}>
                    {month_items.map((value, index) => {
                        return <option key={index} value={value}>{value}월</option>
                    })}
                </select>
                <span className={`material-icons-round ${style.arrow}`}>expand_more</span>
            </div>
            <div className={`${style.dropdown} ${rounded && style.rounded} ${style[size]}  ${disabled && style.disabled}`}>
                <select className={`headline_18pt `} value={day} onChange={(e) => setDay(e.target.value)}>
                    {extraDay.map((value, index) => {
                        return <option key={index} value={value}>{value}일</option>
                    })}
                </select>
                <span className={`material-icons-round ${style.arrow}`}>expand_more</span>
            </div>
        </div>
    )
}
