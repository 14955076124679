import axiosInstance from "../../modules/network";

export const RequestFetch = () => {
    const reservation = async (revNo) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation/inquiry?inqUid=${revNo}`)
            return result.data
        } catch (err) {
            console.error(err)
        }
    }

    const workList = async (cpId) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation/business-hours?cpId=${cpId}`)
            return result.data
        } catch (err) {
            console.error(err)
        }
    }

    const managerList = async (cpId, currentPage) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation/search/manager?cpId=${cpId}&currentPage=${currentPage}&offsetSize=1&pageSize=20`)
            return result.data
        } catch (err) {
            console.error(err)
        }
    }

    const serviceList = async (cpId, currentPage) => {
        try {
            const result = await axiosInstance.get(`/api/user/reservation/search/service?cpId=${cpId}&currentPage=${currentPage}&pageSize=20`)
            return result.data
        } catch (err) {
            console.error(err)
        }
    }

    const requestReservation = async (params) => {
        try {
            const result = await axiosInstance.post('/api/user/reservation/inquiry', params)
            return result.data
        } catch (err) {
            console.error(err)
        }
    }

    return {reservation, workList, managerList, serviceList, requestReservation}
}