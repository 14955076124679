import React from "react";
import style from "../css/module/greenList.module.scss"
import Tags from "./Tags";

export default function GreenList2(props) {
    const {dataArr, className, bottomButton} = props

    const renderTag = (status) => {
        if (status === 2) {
            return (<Tags text={"예약진행중"} color={"default"} dark={true}/>)
        }
        else if (status === 3) {
            return (<Tags text={"예약확정"} color={"blue"} dark={true}/>)
        }
        else if (status === 5 || status === 7 || status === 8) {
            return (<Tags text={"예약취소"} color={"red"} dark={true}/>)
        }
        else if (status === 4) {
            return (<Tags text={"이용완료"} color={"green"} dark={true}/>)
        }
        else if (status === 6) {
            return (<Tags text={"미방문"} color={"red"} dark={true}/>)
        }
        else if (status === 0) {
            return (<Tags text={"예약문의"} color={"green"} dark={true}/>)
        }
        else if (status === 1) {
            return (<Tags text={"문의종료"} color={"default"} dark={true}/>)
        }
    }

    return (
        <ul className={`${style.green_container} ${bottomButton ? style.padding_narrow : style.padding_full} ${className ? className : ""}`}>
            {dataArr.map((data, i) => {
                return (
                    <li className={style.list} key={i}>
                        <label className={`subheader_16pt`}>{data.label}</label>
                        {data.status ? renderTag(data.status)
                            : <p className={"headline_18pt"}>{data.content()}</p>}
                    </li>
                )
            })}

            {bottomButton &&
                <button className={`subheader_14pt ${style.bottomButton}`} onClick={bottomButton.onClick}>{bottomButton.title}</button>}
        </ul>
    )
}
