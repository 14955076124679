import {Suspense, useEffect} from "react";
import './css/App.scss';
import routes from './modules/router';
import {useRoutes, useSearchParams} from 'react-router-dom';
import useSessionStorage from "./modules/useSessionStorage";
import {MOBILE, TABLET, PC} from "./components/MediaQuery";

function App() {

    const [, setDevice] = useSessionStorage("DEVICE")

    let [searchParams] = useSearchParams();

    const routing = useRoutes(routes);

    useEffect(() => {
        let temp = window.navigator.userAgent
        if (temp.match("APP_PARTNERS_ANDROID")) {
            setDevice("android")
        }
        else if (temp.match("APP_PARTNERS_IOS")) {
            setDevice("ios")
        }
        else {
            setDevice("")
        }
    }, [searchParams, setDevice])

    return (<>
        <Suspense fallback="Loading...">
            <MOBILE>
                <div className={"mobile_container"}>
                    {routing}
                </div>
            </MOBILE>
            <TABLET>
                <div className={"pc_container"}>
                    {routing}
                </div>
            </TABLET>
            <PC>
                <div className={"pc_container"}>
                    {routing}
                </div>
            </PC>
        </Suspense>
    </>)
}

export default App;
