import React from "react";
import style from "../css/module/list.module.scss"
import Tags from "./Tags";

export default function List(props) {
    const {title, desc, status, tagInline, date, time, button, onClick, className} = props

    const renderTag = (status) => {
        if (status === "R18001") {
            return (<Tags text={"예약진행중"} color={"default"} dark={true}/>)
        }
        else if (status === "R18002") {
            return (<Tags text={"예약확정"} color={"blue"} dark={true}/>)
        }
        else if (status === "R18003") {
            return (<Tags text={"예약취소"} color={"red"} dark={true}/>)
        }
        else if (status === "R18004") {
            return (<Tags text={"이용완료"} color={"green"} dark={true}/>)
        }
        else if (status === "R18005") {
            return (<Tags text={"미방문"} color={"default"} dark={true}/>)
        }
    }

    return (
        <li className={`${style.list} ${className ? className : ""}`} onClick={onClick}>
            <div>
                <div className={`${style.tagBlock}`}>{status && !tagInline && renderTag(status)}</div>
                <div className={`${style.title} body_16pt bold`}>
                    <p>{title}</p>{status && tagInline && renderTag(status)}
                </div>
                <p className={`${style.desc} body_14pt`}>{desc}</p>{date && time && <p className={`${style.date} body_14pt`}>{date} / {time}</p>}
            </div>
            {button && <span className={`material-icons-round ${style.button}`}>arrow_forward_ios</span>}
        </li>
    )
}
