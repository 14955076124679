import React from "react";
import style from "../css/module/toast.module.scss"

export default function Toast(props) {
    const {text, image} = props

    return (
        <div className={`${style.content}`}>
            <span className={`${style.lightbulb}`} style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/image/' + image})`
            }}/>
            {text}
        </div>
    )
}
