import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useSessionStorage from "../modules/useSessionStorage";
import qs from 'qs';

const axios = require('axios');


function KakaoNavi() {

    const {Kakao} = window
    let location = useLocation()
    const navigate = useNavigate();
    let address = location.state.address || "성동구 왕십리로 130"
    const [device, setDevice] = useSessionStorage("DEVICE")
    useEffect(() => {
        // SDK를 초기화 합니다. 사용할 앱의 JavaScript 키를 설정해 주세요.
        // if (!Kakao.isInitialized()) {
        //     Kakao.init(process.env.REACT_APP_KAKAO_KEY);
        // }
        // SDK 초기화 여부를 판단합니다.

        const data = qs.stringify({
            'query': address,
            'page': 1,
            'size': 10
        })
        axios
            .get(`https://dapi.kakao.com/v2/local/search/address.json?${data}`, {
                headers: {
                    'Authorization': `KakaoAK 2aedc06e3fb246033130c4c1b800c45c`,
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            })
            .then((result) => {
                //console.log(result.data.documents[0].road_address)
                let response = result.data.documents[0].road_address
                return response
            })
            .then((response) => {
                navi(response)
            })
    }, [])

    const navi = (latlng) => {
        // if (Kakao.isInitialized()) {
        const info = {
            "name": address,
            "x": String(latlng.x),
            "y": String(latlng.y)
        }
        //console.log(info)
        Kakao.Navi.start({
            name: address,
            x: Number(latlng.x),
            y: Number(latlng.y),
            coordType: 'wgs84',
        })
        // }
    }

    return (
        <div></div>
    )
}

export default KakaoNavi
