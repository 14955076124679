import React from "react";
import style from "../css/module/modal.module.scss"
import Buttons from "./Buttons";

export default function Modal(props) {

    const {close, header, headerStyle = "default", buttons, buttonStyle = "default", setOpen, content, contentAlign = "center", children} = props


    const renderButtons = (buttons) => {
        if (buttons.length === 1) {
            return (
                <div className={`${style.buttons} ${style.default}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"default"} onClick={buttons[0].onClick}/>
                </div>
            )
        }
        else if (buttonStyle === "upDown" && buttons.length === 2) {
            return (
                <div className={`${style.buttons} ${style.upDown}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"default"} onClick={buttons[0].onClick}/> <Buttons value={buttons[1].title} size={"extra_small"} type={"text"} onClick={buttons[1].onClick}/>
                </div>
            )
        }
        else if (buttonStyle === "default" && buttons.length === 2) {
            return (
                <div className={`${style.buttons} ${style.default}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"text"} onClick={buttons[0].onClick}/> <Buttons value={buttons[1].title} size={"extra_small"} type={"default"} onClick={buttons[1].onClick}/>
                </div>
            )
        }
    }

    return (
        <div className={style.background}>
            <div className={style.modal}>

                <div className={style.header}>
                    {header && <p className={`${style[headerStyle]} ${headerStyle === "default" ? "headline_18pt" : "headline_24pt"}`} dangerouslySetInnerHTML={{__html: header}}/>} {close && <span onClick={() => {
                    setOpen(false)
                }} className="material-icons-round">close</span>}
                </div>

                <div className={style.content}>
                    {children}
                </div>

                {content && <div className={`${style.content} ${style[contentAlign]} subheader_14pt`} dangerouslySetInnerHTML={{__html: content}}></div>}

                {buttons && renderButtons(buttons)}
            </div>
        </div>
    )
}
