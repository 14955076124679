import React, {useEffect, useRef} from "react";
import style from "../css/module/textbox.module.scss";

export default function TextBox(props) {
    const {title, value, events, placeholder} = props
    const handleChange = (e) => onChange(e.target.value);
    const {onChange} = events;
    const fieldProps = {
        value,
        placeholder,
        onChange: handleChange,
        disabled: props.disabled
    };
    const textEl = useRef(null);
    const getFontSize = () => {
        if (props.size === "medium") {
            return "body_14pt"
        }
        else if (props.size === "large") {
            return "body_16pt"
        }
    }
    useEffect(() => {
        handleResizeHeight()
    }, [value])
    const handleResizeHeight = () => {
        const obj = textEl.current
        obj.style.height = 'auto'
        obj.style.height = obj.scrollHeight + 'px'
    };
    return (
        <div>
            <label className={`${style.title} subheader_14pt`}>{title}</label>
            <div className={`${style.inputbox} ${props.error ? style.error : ''} ${props.disabled ? style.disabled : ''}`}>
                <textarea {...fieldProps} className={getFontSize()} ref={textEl}/>
            </div>
            <p className={`${props.error ? style.error_message : ''} ${props.comment ? style.comment : ''} caption_12pt_regular`}>{props.comment}</p>
        </div>
    )
}
