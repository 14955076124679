import React from "react";
import style from "../css/module/radio.module.scss"

export default function Radio(props) {

    const {value, setValue} = props

    const onClickHandler = (e) => {
        setValue(!value)
    }

    return (
        <div className={`${style.toggle} ${style[props.size]} ${style[props.type]} ${value ? style.on : style.off}`} onClick={(e) => onClickHandler(e)}>
            <div className={style.circle}/>
        </div>
    )
}
