import axiosInstance from "../../modules/network";
import config from "../../modules/Config";

export const ExternalFetch = () => {
    const list = async () => {
        return []
    }

    const detail = async (key) => {
        try {
            const result = await axiosInstance.get(
                `/ext`, {
                    params: {
                        'key': key
                    }
                }
            )
            return result.data
        } catch (err) {
            if (config.debug) {
                console.error(err)
            }
            else {
                console.trace(err)
            }
            return {}
        }
    }

    const pay = async (data) => {
        try {
            const result = await axiosInstance.get(
                `/ext`, {
                    params: Object.assign(data, {
                        'action': 'payment'
                    })
                }
            )
            return result.data
        } catch (err) {
            console.error(err)
        }
    }

    const refundPolicy = async (key) => {
        try {

            return {}
        }
        catch(err) {
            if (config.debug) {
                console.error(err)
            }
            else {
                console.trace(err)
            }
            return {}
        }
    }

    return {list, detail, pay}
}
