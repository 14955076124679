import React from "react";
import style from "../css/module/button.module.scss"

export default function Buttons(props) {
    const {size, type, value, disabled, onClick, icon, className} = props

    const getFontSize = () => {
        if (size === "extra_small") {
            return "subheader_14pt"
        }
        else if (size === "small") {
            return "subheader_16pt"
        }
        else if (size === "medium" || size === "large") {
            return "headline_18pt"
        }
    }

    const defaultProps = {
        className: `${style.button} ${style[type]} ${style[size]} ${getFontSize()} ${className}`,
        disabled: disabled,
        onClick: onClick
    }
    return (
        <button {...defaultProps}>
            {icon && <span className={`material-icons-round ${style.icon}`}>{icon}</span>} {value}
        </button>
    )
}
