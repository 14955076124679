import React, {useEffect, useRef} from "react";


function KakaoMap({address}) {

    const {kakao} = window
    const container = useRef()
    const onLoadKakaoMap = () => {
        kakao.maps.load(() => {
            // https://apis.map.kakao.com/web/documentation/#load_StaticMethods
            // v3가 모두 로드된 후, 이 콜백 함수가 실행됩니다
            const mapOption = {
                center: new kakao.maps.LatLng(127.044754852849, 37.5491962171866), // 지도의 중심좌표
                level: 2, // 지도의 확대 레벨
            };
            // 지도를 생성합니다
            let map = new kakao.maps.Map(container.current, mapOption);
            //console.log("3. kakao maps load")

            // 주소-좌표 변환 객체를 생성합니다
            const geocoder = new kakao.maps.services.Geocoder();
            // console.log(geocoder);
            // 주소로 좌표를 검색합니다
            geocoder.addressSearch(address, function (result, status) {
                // 정상적으로 검색이 완료됐으면
                if (status === kakao.maps.services.Status.OK) {
                    const coords = new kakao.maps.LatLng(result[0].y, result[0].x);

                    // 결과값으로 받은 위치를 마커로 표시합니다
                    const marker = new kakao.maps.Marker({
                        map: map,
                        position: coords
                    });

                    // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                    map.setCenter(coords);
                }
            });
        });
    };

    useEffect(() => {
        if (kakao) {
            if (kakao.maps) {
                // 첫 로딩 시
                // kakao.js 요청 없음
                //console.log("2. kakao script load")
                onLoadKakaoMap()
            }
            else {
                // 새로고침 시
                // dapi.kakao.com 요청 후 kakao.js 요청 한번 더 들어감
                //console.log("2. refresh")
                kakao.addEventListener("load", onLoadKakaoMap);
            }
        }
    }, [kakao])

    return (
        <div id="map" className="kakao_map" ref={container}/>
    )
}

export default KakaoMap