import React, {useEffect, useState} from "react";
import style from "../css/module/bottomModalRadio.module.scss"
import Buttons from "./Buttons";

export default function BottomModalRadio(props) {

    const {
        close,
        header,
        headerStyle = "default",
        buttons,
        buttonStyle = "default",
        setOpen,
        content,
        contentAlign = "center",
        value,
        setValue,
        title,
        setTitle
    } = props

    const [radio, setRadio] = useState("");

    function selectedRadio(code, title) {
        setValue(code)
        if (setTitle !== null && setTitle !== undefined) {
            setTitle(title)
        }
    }

    useEffect(() => {
        // console.log(radio)
    }, [radio])

    const renderButtons = (buttons) => {
        if (buttons.length === 1) {
            return (
                <div className={`${style.buttons} ${style.default}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"default"} onClick={buttons[0].onClick}/>
                </div>
            )
        }
        else if (buttonStyle === "upDown" && buttons.length === 2) {
            return (
                <div className={`${style.buttons} ${style.upDown}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"default"} onClick={buttons[0].onClick}/>
                    <Buttons value={buttons[1].title} size={"extra_small"} type={"text"} onClick={buttons[1].onClick}/>
                </div>
            )
        }
        else if (buttonStyle === "default" && buttons.length === 2) {
            return (
                <div className={`${style.buttons} ${style.default}`}>
                    <Buttons value={buttons[0].title} size={"extra_small"} type={"text"} onClick={buttons[0].onClick}/>
                    <Buttons value={buttons[1].title} size={"extra_small"} type={"default"} onClick={buttons[1].onClick}/>
                </div>
            )
        }
    }
    return (
        <div className={style.background}>
            <div className={style.modal}>

                <div className={style.header}>
                    {header && <p className={`${style[headerStyle]} headline_24pt`} dangerouslySetInnerHTML={{__html: header}}/>} {close && <span onClick={() => {
                    setValue("H00001")
                    if (setTitle !== undefined) {
                        setTitle("아무 시간이나 괜찮아요")
                    }
                    setOpen(false)
                }} className="material-icons-round">close</span>}
                </div>

                {content.map((obj, index) => {
                    return <div key={index} className={`${style.controls_radio} mb10`} onClick={(e) => selectedRadio(obj.code, obj.title)}>
                        <div className={`${style.title} headline_18pt`}>{obj.title}</div>
                        <div className={`${style.toggle}  ${obj.code === value && style.on}`} value={obj.code}>
                            <div className={style.circle}/>
                        </div>
                    </div>
                })}


                {content && <div className={`${style.content} ${style[contentAlign]} subheader_14pt`}></div>}

                {buttons && renderButtons(buttons)}
            </div>
        </div>
    )
}
