/**
 * 예약금 결제 필요한 예약
 * 예약금 결제 / 예약 취소 가능
 * */

import React, {useEffect, useState} from 'react';
import "../../css/View.scss";
import style from './Payment.module.scss';
import GreenList from "../../components/GreenList";
import GreenList2 from "../../components/GreenList2";
import KakaoMapContainer from "../../components/KakaoMapContainer";
import BottomButtons from "../../components/BottomButtons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PaymentFetch} from "../../model/Payment/Payment";
import {ExternalFetch} from "../../model/Payment/ExternalFetch";
import * as common from "../../modules/Common";
import Toast from "../../components/Toast";
import SnackBar from "../../components/SnackBar";
import Modal from "../../components/Modal";
import moment, {min} from "moment";
import 'moment/locale/ko'

function getTimeStringSeconds(seconds) {
    seconds = Number(seconds);


}

const limitTime = (created) => {
    const temp = moment(created * 1000)
    const now = moment()

    if (temp.isAfter(now)) {

        const duration = moment.duration(temp.diff(now))

        const hours = duration.hours()
        const minutes = duration.minutes()
        const seconds = duration.seconds()
        return `${hours}:${minutes}:${seconds}`

        // return getTimeStringSeconds(duration.asSeconds());

        /*const gap = (now.getTime() - temp.getTime()) / 1000
        console.info(gap)
        if (3600 >= gap) {
            return getTimeStringSeconds(3600 - gap)
        }
        else {
            return '00:00:00'
        }*/
    }
}

export default function Reservation() {
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    let key = searchParams.get("key") || searchParams.get("revNo")

    const {detail} = ExternalFetch()
    const [content, setContent] = useState({})
    const [time, setTime] = useState('00:00:00')
    const [showSnack, setShowSnack] = useState(false)
    const [cancelPayment, setCancelPayment] = useState(false)

    useEffect(() => {
        detail(key).then(result => setContent(result.data))
    }, [])

    useEffect(() => {
        if (content === undefined || content === null) {
            setShowSnack(true)
        }
    }, [content])

    useEffect(() => {
        const time = content?.depositExpired

        if (undefined !== time) {

            console.info(time * 1000);

            const timerId = setInterval(() => {
                const timeout = limitTime(time)
                setTime(timeout)
            }, 1000)

            return () => clearInterval(timerId)
        }

        /*const createdAt = content?.reservationResponse?.createdAt

        if (createdAt !== undefined) {
            const timerId = setInterval(() => {

                const timeout = limitTime(createdAt)
                setTime(timeout)
            }, 1000)

            return () => clearInterval(timerId)
        }*/
    }, [content])

    const renderStatus = () => {
        const status = content?.status

        if (status === 2) {
            return (
                <div className={"toast_message"}>
                    <span className={"subheader_14pt"}>{time}</span>
                    예약확정을 위해 시간 내 결제해 주세요.</div>
            )
        }

        if (status === 3) {
            return (
                <Toast text={"예약이 확정되었습니다."} image={"lightbulb_outline.png"}/>
            )
        }

        /*const revStatus = content?.reservationResponse?.revStatus
        if (revStatus === 'R18001') {
            return (
                <div className={"toast_message"}>
                    <span className={"subheader_14pt"}>{time}</span>
                    예약확정을 위해 시간 내 결제해 주세요.</div>
            )
        }
        else if (revStatus === 'R18002') {
            return (
                <Toast text={"예약이 확정되었습니다."} image={"lightbulb_outline.png"}/>
            )
        }*/
    }

    const renderButton = () => {

        const status = content?.status
        const deposit = content?.deposit ?? 0

        if (status === 2 && deposit !== 0) {
            return (
                <BottomButtons buttons={[
                    {
                        title: "예약금 결제하기",
                        onClick: () => {
                            navigate("/reservation/payment", {state: {key: key, amount: content.deposit}})
                        }
                    }
                ]}/>
            )
        }
        /*else if (status === 3) {
            if (deposit !== 0) {
                return (
                    <BottomButtons buttons={[
                        {
                            title: "예약취소",
                            onClick: () => {
                                navigate("/reservation/payment/cancel", {state: {key: key}})
                            }
                        }
                    ]}/>
                )
            }
            else {
                return (
                    <BottomButtons buttons={[
                        {
                            title: "예약취소",
                            onClick: () => {
                                navigate("/reservation/cancel", {state: {key: key}})
                                //navigate("/payment/cancel/reason", {state: {key: key}})
                                setCancelPayment(true)
                            }
                        }
                    ]}/>
                )
            }
        }*/
        else if (status > 3) {
            /*return (
                <BottomButtons buttons={[
                    {
                        title: "새로운 예약 문의하기",
                        onClick: () => {
                            navigate(`/contact?revNo=${key}`)
                        }
                    }
                ]}/>
            )*/
        }

        /*const revStatus = content?.reservationResponse?.revStatus
        const paymentStatus = content?.reservationResponse?.paymentStatus
        const deposit = content?.reservationResponse?.deposit

        if (revStatus === 'R18001' && paymentStatus === 'P18006' && deposit !== 0) {
            return (
                <BottomButtons buttons={[
                    {
                        title: "예약금 결제하기",
                        onClick: () => {
                            navigate("/payment/step1", {state: {revNo: key}})
                        }
                    }
                ]}/>
            )
        }
        else if (revStatus === 'R18002') {
            if (deposit !== 0) {
                return (
                    <BottomButtons buttons={[
                        {
                            title: "예약취소",
                            onClick: () => {
                                navigate("/payment/cancel", {state: {revNo: key}})
                            }
                        }
                    ]}/>
                )
            }
            else {
                return (
                    <BottomButtons buttons={[
                        {
                            title: "예약취소",
                            onClick: () => {
                                //navigate("/payment/cancel/reason", {state: {key: key}})
                                setCancelPayment(true)
                            }
                        }
                    ]}/>
                )
            }
        }
        else if ((revStatus === 'R18003' && paymentStatus === 'P18005')
            || (revStatus === 'R18003' && paymentStatus === 'P18004')
            || revStatus === 'R18004'
            || revStatus === 'R18005'
            || paymentStatus === 'P18002'
            || paymentStatus === 'P18006'
        ) {
            return (
                <BottomButtons buttons={[
                    {
                        title: "새로운 예약 문의하기",
                        onClick: () => {
                            navigate(`/contact?revNo=${key}`)
                        }
                    }
                ]}/>
            )
        }*/
    }

    const renderSnack = () => {

        const status = content?.status
        const deposit = content?.deposit ?? 0

        if (status === 6) {
            return (
                <SnackBar title={`예약이 취소되었습니다.`} icon={"check"} color={"#93FD53"} showSnack={showSnack} setShowSnack={setShowSnack}/>
            )
        }
        else if (status === 3) {
            return (
                <SnackBar title={`예약금 ${deposit || "0"}원이 결제되었습니다.`} icon={"check"} color={"#93FD53"} showSnack={showSnack} setShowSnack={setShowSnack}/>
            )
        }

        /*const revStatus = content?.reservationResponse?.revStatus
        const paymentStatus = content?.reservationResponse?.paymentStatus
        const deposit = common.numberWithCommas(content?.reservationResponse?.deposit)

        if (revStatus === 'R18003' && paymentStatus === 'P18003') {
            return (
                <SnackBar title={`예약이 취소되었습니다.`} icon={"check"} color={"#93FD53"} showSnack={showSnack} setShowSnack={setShowSnack}/>
            )
        }
        else if (revStatus === 'R18002' && paymentStatus === 'P18003') {
            return (
                <SnackBar title={`예약금 ${deposit || "0"}원이 결제되었습니다.`} icon={"check"} color={"#93FD53"} showSnack={showSnack} setShowSnack={setShowSnack}/>
            )
        }*/
    }

    return (<>
        <div id="wrap" className="bg_beige_light">
            <div className="container">
                <div id={"view"} className={"mb93"}>
                    <div className={"logo_wrap y73"}>
                        <embed type="image/svg+xml" src="/image/primary_logo.svg"/>
                    </div>

                    <div className={"mb24"}>{renderStatus()}</div>

                    {/*{content?.paymentRefundResponse &&*/} {/*    <div className={style.cancel_info}>*/} {/*        <ul>*/} {/*            <li>예약 취소 및 환불이 완료되었습니다.</li>*/} {/*            <li>취소 시간: {content?.paymentRefundResponse?.refundRequestDate}</li>*/} {/*            <li>예약금: {common.numberWithCommas(content?.paymentRefundResponse?.deposit)}원</li>*/} {/*            <li>환불금액: {common.numberWithCommas(content?.paymentRefundResponse?.amount)}원</li>*/} {/*            <li>자세한 내용은 02-1234-5678로 문의주시기 바랍니다.</li>*/} {/*        </ul>*/} {/*    </div>}*/}
                    <div>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>예약정보</h2>
                        </div>
                        <div>
                            <GreenList2 dataArr={[
                                {
                                    label: "예약 번호",
                                    content: function () {
                                        return content?.displayId ?? "-"
                                    }
                                },
                                {
                                    label: "예약 일시",
                                    content: function () {
                                        if (content.hasOwnProperty("startDate")) {
                                            moment.locale('ko')
                                            return moment(content.startDate * 1000).format("LLL")
                                        }
                                        return "-"
                                    }
                                },
                                {
                                    label: "예약 상태",
                                    status: content?.status ?? -1
                                },
                                {
                                    label: "업체명",
                                    content: function () {
                                        return content?.group?.title ?? "-"
                                    }
                                },
                                {
                                    label: "서비스 정보",
                                    content: function () {
                                        if (content.hasOwnProperty('productList') && content.productList.length > 0) {
                                            let list = content.productList

                                            if (list.length > 1) {
                                                return `${list[0].name} 외 ${list.length - 1}건`
                                            }
                                            return list[0].name
                                        }
                                        return "-"
                                    }
                                },
                                {
                                    label: "예약금",
                                    content: function () {
                                        return (content?.deposit ?? 0) > 0 ? `${common.numberWithCommas(content?.deposit)}원` : '없음'
                                    }
                                }
                            ]}/>
                        </div>
                    </div>

                    {/*{content?.paymentRefundResponse &&*/} {/*    <div className={"pb24"}>*/} {/*        <div className={"y16"}>*/} {/*            <h2 className={"title headline_18pt"}>환불정보</h2>*/} {/*        </div>*/} {/*        <div>*/} {/*            <GreenList dataArr={[*/} {/*                {*/} {/*                    label: "결제상태",*/} {/*                    content: `${content?.paymentRefundResponse?.paymentStatus || "-"}`*/} {/*                },*/} {/*                {*/} {/*                    label: "환불일시",*/} {/*                    content: `${content?.paymentRefundResponse?.refundRequestDate || "-"}`*/} {/*                },*/} {/*                {*/} {/*                    label: "예약금",*/} {/*                    content: `${common.numberWithCommas(content?.paymentRefundResponse?.deposit) || "0"} 원`*/} {/*                },*/} {/*                {*/} {/*                    label: "환불금액",*/} {/*                    content: `${common.numberWithCommas(content?.paymentRefundResponse?.amount) || "0"} 원`*/} {/*                }*/} {/*            ]}/>*/} {/*        </div>*/} {/*    </div>}*/}

                    {/*{content?.storeReservationInfoResponse?.reservationInfoContents &&*/} {/*    <div className={"info_wrap"}>*/} {/*        <h3 className={"subheader_16pt"}>*/} {/*            <span className="material-icons mr8">notification_important</span>*/} {/*            예약 시 안내 사항*/} {/*        </h3>*/} {/*        <ul className={"subheader_14pt"}>*/} {/*            <li dangerouslySetInnerHTML={{__html: content?.storeReservationInfoResponse?.reservationInfoContents.replace(/\n/g, '<br/>') || ''}}/>*/} {/*        </ul>*/} {/*    </div>}*/}

                    {content?.deposit !== 0 &&
                        <div className={"warning_wrap"}>
                            <h3 className={"subheader_16pt"}>
                                <span className="material-icons mr8">notification_important</span>
                                예약금 정책
                            </h3>
                            <ul className={"subheader_14pt"}>
                                <li>7일 전 취소: 100% 환불</li>
                                <li>당일예약 취소 또는 변경, 미방문(노쇼): 0% 환불</li>
                            </ul>
                        </div>}

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>반려동물 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려동물명",
                                    content: `${content?.pet?.name || '-'}`
                                },
                                {
                                    label: "반려동물종",
                                    content: `${content?.pet?.species?.name || '-'}`
                                }
                            ]}/>
                        </div>
                    </div>

                    {content?.memo &&
                        <div className={"pb24"}>
                            <div className={"y16"}>
                                <h2 className={"title headline_18pt"}>요청사항</h2>
                            </div>
                            <div className={"customer_request headline_18pt"}>
                                {content?.memo || '-'}
                            </div>
                        </div>}

                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>예약자 정보</h2>
                        </div>
                        <div>
                            <GreenList dataArr={[
                                {
                                    label: "반려인명",
                                    content: `${content?.subscriber?.name || '-'}`
                                },
                                {
                                    label: "휴대폰 번호",
                                    content: `${content?.subscriber?.cellnumber || '-'}`
                                }
                            ]}/>
                        </div>
                    </div>
                    <div className={"pb24"}>
                        <div className={"y16"}>
                            <h2 className={"title headline_18pt"}>업체 정보</h2>
                        </div>
                        <div className={"map_wrap"}>
                            <KakaoMapContainer company={content?.group?.title || ''} address1={content?.group?.address?.address || ''} address2={""} addressPost={""} tel={content?.group?.contact || ''}/>
                        </div>
                    </div>
                    <div className={"px-2 color-information body_14pt"}>
                        <ul>
                            <li>주식회사 포들러스&nbsp;|&nbsp;<a className={"color-information"} href="https://pawdly.oopy.io/11e65733-01d4-4914-9523-89edcd8fae5d" target={"_blank"}>서비스 이용약관</a></li>
                            <li>대표자 : 이신혜</li>
                            <li>사업자등록번호 : 696-86-02652</li>
                            <li>통신판매업 : 제 2022-서울성동-01686호</li>
                            <li>주소 : 서울특별시 성동구 왕십리로 130, 10층(성수동1가, KCC 프리미어 타워)</li>
                            <li>Tel : 02-6953-5788</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        {renderButton()} {renderSnack()}

        {cancelPayment &&
            <Modal header={"예약을 취소하시겠어요?"} headerStyle={"default"} setOpen={setCancelPayment} contentAlign={"left"} buttonStyle={"upDown"} buttons={[
                {
                    title: "네 취소할래요",
                    onClick: () => {
                        navigate("/payment/cancel/reason", {state: {revNo: key}})
                    }
                },
                {
                    title: "아니오",
                    onClick: () => {
                        setCancelPayment(false)
                    }
                }
            ]}> </Modal>}
    </>)
}
