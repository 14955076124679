import React, {useEffect, useState} from 'react';
import style from './Request.module.scss';
import Header from "../../components/Header";
import {useNavigate} from "react-router-dom";
import * as common from "../../modules/Common";
import {extraHoliday} from "../../modules/Common";
import * as request from "../../model/Request/Request";

export default function RequestTemp() {

    const navigate = useNavigate()
    const information = {
        "revNo": "D20220222-5194",
        "customerPetResponse": {"ptCode": "P20220215153338-7321", "ptName": "라라"},
        "customerResponse": {"cbId": 3953, "cbName": "윤용철", "cbHp": "01096591368"},
        "storeResponse": {
            "cpId": 1341,
            "cpName": "카카오동물병원",
            "cpAddr": "서울 성동구 왕십리로 130 (성수동1가, KCC 프리미어타워)",
            "cpTel": "01012341234"
        },
        "storeReservationInfoResponse": {
            "cpId": 1341,
            "reservationInfoContents": "예약시 안내사항 입니다.\n예약신청과 예약완료는 다름을 유의해주세요.\n노쇼는 사전에 연락부탁드립니다!!!"
        }
    }

    const cpId = information.storeResponse.cpId

    const today = common.today()
    const [date, setDate] = useState(today)
    const [monthItems, setMonthItems] = useState([])
    const [dayItems, setDayItems] = useState([])
    const fetch = request.RequestFetch()

    const [extras, setExtras] = useState([])

    useEffect(() => {
        fetch.workList(cpId).then(result => {
            setExtras(result.data)
        })

    }, [])

    useEffect(() => {
        const month = common.limitMonth(today, date.year, date.month)
        const day = common.limitDay(today, date.year, date.month)
        setMonthItems(month)
        const items = extraHoliday(extras, date.year, date.month, day)
        if (items !== null) {
            setDayItems(items)
        }

    }, [date, extras])


    return (
        <>
            <div id="wrap" className="bg_beige_light">
                <Header title={"예약 문의하기"} backButton={false} type={"page"} closeButton={{
                    onClick: () => navigate(-1)
                }}/>
                <div className={`${style.request} container pt64 pb92`}>
                    <div className={style.row_wrap}>
                        <div className={style.input_wrap}>
                            <label>업체명</label>
                            <div className={style.text_wrap}>
                                {information.storeResponse.cpName}
                            </div>
                        </div>
                    </div>
                    <div className={style.row_wrap}>
                        <div className={style.input_wrap}>
                            <label>반려견 및 고객명</label>
                            <div className={style.text_wrap}>
                                {`${information.customerPetResponse.ptName} (${information.customerResponse.cbName})`}
                            </div>
                        </div>
                    </div>
                    <div className={style.row_wrap}>
                        <div className={style.input_wrap}>
                            <label>반려견 및 고객명
                                <span>*</span>
                            </label>
                            <div className={style.selects_wrap}>
                                <div className={style.select_wrap}>
                                    <select value={date.year} onChange={(e) =>
                                        setDate({...date, year: e.target.value})}>
                                        <option value={`${today['year']}`}>{today['year']}년</option>
                                        <option value={`${Number(today['year']) + 1}`}>{Number(today['year']) + 1}년
                                        </option>
                                    </select>
                                </div>
                                <div className={style.select_wrap}>
                                    <select value={date.month} onChange={(e) =>
                                        setDate({...date, month: e.target.value})}>
                                        {monthItems.map((value, index) => {
                                            return <option key={index} value={value}>{value}월</option>
                                        })}
                                    </select>
                                </div>
                                <div className={style.select_wrap}>
                                    <select value={date.day} onChange={(e) =>
                                        setDate({...date, day: e.target.value})}>
                                        {dayItems.map((value, index) => {
                                            return <option key={index} value={value}>{value}일</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.row_wrap}>
                        <div className={style.input_wrap}>
                            <label>희망 시작 시간
                                <span>*</span>
                            </label>
                            <div className={style.text_wrap}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
