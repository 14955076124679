import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import BottomButtons from "../../components/BottomButtons";
import style from './Payment.module.scss';
import CheckBox from "../../components/CheckBox";
import BottomModalRadio from "../../components/BottomModalRadio";
import {useLocation, useNavigate} from "react-router-dom";
import {PaymentFetch} from "../../model/Payment/Payment";
import * as common from "../../modules/Common";
import SnackBar from "../../components/SnackBar";
import {ExternalFetch} from "../../model/Payment/ExternalFetch";
import moment from "moment";
import 'moment/locale/ko'

const paymentType = [
    {
        title: '신용 / 체크카드',
        code: 'CARD'
    },
    {
        title: '토스 페이',
        code: 'TOSSPAY'
    }
]

export default function PaymentStep1() {

    const navigate = useNavigate()

    const [openPayment, setOpenPaymentMethod] = useState(false)
    const [payment, setPayment] = useState("")
    const location = useLocation()

    const key = location.state.key

    //const decodeRevNo = aes256Decode(decodeURIComponent(unescape(key)))
    const [showSnack, setShowSnack] = useState(false)
    const [notification, setNotification] = useState('')
    const [icon, setIcon] = useState('check')

    const [content, setContent] = useState({})
    const [refundPolicy, setRefundPolicy] = useState({})
    const {detail, pay} = ExternalFetch()

    const [agree, setAgree] = useState(false)

    useEffect(() => {
        detail(key).then(result => {
            setContent(result.data)
        })
    }, [])

    const warning = () => {
        moment.locale('ko')
        const revCancelDeadlineDt = moment().add(refundPolicy?.refund ?? 0, 'd').format("LLL")
        let percent = '100'
        return (
            <div className={`${style.toast_message}`}>
                <span className={"subheader_14pt"}>{revCancelDeadlineDt}</span>
                까지 취소시, 예약금의 {percent}%가 환불됩니다. </div>
        )
    }
    const onPayment = () => {
        setOpenPaymentMethod(false)
        const params = {
            "key": key,
            "param0": payment

        }
        pay(params).then(result => {
            const res = result.data
            navigate(`/reservation?key=${key}`)
        })
    }

    return (<>
        <div id="wrap" className="bg_beige_light">
            <Header title={"예약금 결제하기"} backButton={true} type={"page"}/>
            <div className="container pt64 pb92">
                <div className={`${style.title} headline_18pt`}>
                    <span>결제 금액</span>
                </div>
                <div className={`${style.list} ${style.bt_line} headline_16pt`}>
                    <span>예약금</span>
                    <span>{common.numberWithCommas(content.deposit ?? 0)}원</span>
                </div>
                <div className={`${style.list} headline_16pt`}>
                    <span>총 결제금액</span>
                    <span>{common.numberWithCommas(content.deposit ?? 0)}원</span>
                </div>
                <div className={"mt16 mb24"}>
                    {warning()}
                </div>
                <div className={`${style.title} headline_18pt`}>
                    <span>결제 내용 확인 및 결제 동의</span>
                </div>
                <div className={style.checkbox_wrap}>
                    <CheckBox type={'primary'} size={'normal'} value={agree} setValue={setAgree}/>
                    <span>결제 진행 필수 동의</span>
                </div>
                <div className={style.warning_wrap}>
                    <p>개인정보 수집, 이용 및 처리 동의 (필수)</p>
                    <h3 className={"subheader_16pt"}>
                        <span className="material-icons mr8">
                            notification_important
                        </span>
                        예약금 정책
                    </h3>
                    <ul className={"subheader_14pt"}>
                        {(refundPolicy?.refund ?? 0) > 0 &&
                            <li>{refundPolicy?.refund}일 전 취소: 100% 환불</li>
                        }
                        <li>예약일로부터 {refundPolicy?.refund ?? 0}일 전, 직전 취소, 미방문(노쇼): 0% 환불</li>
                    </ul>
                </div>
            </div>
        </div>
        <BottomButtons buttons={[
            {
                title: `${common.numberWithCommas(content.deposit ?? 0)}원 결제하기`,
                disable: !agree,
                onClick: () => {
                    setOpenPaymentMethod(true)
                }
            }
        ]}/> {openPayment &&
        <BottomModalRadio close={true} header={"결제수단"} headerStyle={"default"} setOpen={setOpenPaymentMethod} contentAlign={"left"} value={payment} setValue={setPayment} content={paymentType} buttonStyle={"upDown"} buttons={[
            {
                title: "확인",
                onClick: () => {
                    onPayment()
                }
            }
        ]}> </BottomModalRadio>
    } <SnackBar title={notification} icon={icon} color={"#BA1B1B"} showSnack={showSnack} setShowSnack={setShowSnack}/>
    </>)
}
